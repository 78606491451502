import React, { useState, useEffect, useRef } from "react";
import { Button, Col, Row, Modal, Card } from "react-bootstrap";
import "./ModalAnswerReserve.scss";
import SimpleBarReact from "simplebar-react";

import { Formik, Form } from "formik";
import { useField } from "formik";
import * as Yup from "yup";
import SkeletonLoader from "tiny-skeleton-loader-react";
import Tchat from "../../../tchat/index";
import { reserve_status } from "../../../../constants/reserve_status";

const GenericFomikTextInput = ({ children, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);

  return (
    <div className={"form-group"}>
      {children ? (
        <label className="modal-span-title" htmlFor={props.id || props.name}>
          {children}
        </label>
      ) : null}
      <br />
      <input className="modal-input-col-left" {...field} {...props} />
      <div className="error">
        {meta.touched && meta.error ? meta.error : null}
      </div>
    </div>
  );
};

function ModalAnswerReserve(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [conversationArray, setConversationArray] = useState([]);

  /******
   *
   * functions
   *
   ******/

  const handleSubmitMethod = async (values) => {
    setIsLoading(true);

    if (!props.selectedreserve) {
      throw new Error("Impossible to find the Reserve !");
    }

    try {
      const response = await putReserve(values, props.selectedreserve.id);
      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || response.status);
      }
      return await response.json();
    } catch (error) {
      console.log(error);
      throw new Error(error?.message || "Oops, une erreur est survenue");
    }
  };

  const putReserve = async (values, id) => {
    const url = new URL(props.base_url + `/reserves_v2/${id}/solved`);
    const token = localStorage.getItem("fleetToken");
    const body = {};

    setIsLoading(true);

    if (values?.message?.length > 0) {
      body["response"] = values.message;
    }

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);
    let myInit = {
      method: "PUT",
      headers: myHeaders,
      cache: "no-cache",
      body: JSON.stringify(body),
    };

    return fetch(url, myInit);
  };

  useEffect(() => {
    setConversationArray(buildConversationArray(props.selectedreserve));

    function buildConversationArray(reserve) {
      const temporaryConversation = [];

      // Message de l'utilisateur créant la reserve
      temporaryConversation.push({
        text: props.selectedreserve?.message || "Message indisponible",
        reserve: true,
        date: new Date(props.selectedreserve?.created_at),
        id: 1,
        username: props.selectedreserve?.receiver_name || "n.c",
      });

      // L'administrateur n'a pas encore traité la reserve

      if (reserve?.response) {
        temporaryConversation.push({
          text: reserve?.response,
          reserve: false,
          date: new Date(props.selectedreserve?.created_at),
          id: 3,
        });
      }

      // L'administrateur à traitée la reserve sans laisser de message
      if (!reserve?.response && reserve?.status === reserve_status.SOLVED) {
        temporaryConversation.push({
          text: "Le service de livraison a traité la réserve sans laisser de commentaire.",
          reserve: false,
          date: new Date(reserve.updated_at),
          id: 3,
        });
      }
      return temporaryConversation;
    }
  }, [props.selectedreserve]);

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="md"
      onShow={() => {}}
    >
      {/* <div className="background-modal-fleet-add-truck" /> */}
      <Formik
        initialValues={{ message: "" }}
        validationSchema={Yup.object({
          message: Yup.string(),
        })}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmitMethod(values)
            .then(async (response) => {
              setIsLoading(false);
              setSubmitting(false);
              setError(null);
              props.onHide(response?.has_been_updated);
            })
            .catch((error) => {
              console.error(error.message);
              setError(error.message);
            });
        }}
      >
        {({ errors, values, submitForm, setFieldValue }) => (
          <Form>
            <Modal.Header className="modal-add-vehicule-header" closeButton>
              <div className="claim-header-inner-modal">
                <div>
                  <h5>
                    Réserve{" "}
                    {props.selectedreserve?.delivery_detail_key
                      ? `${props.selectedreserve.delivery_detail_key}`
                      : "000000"}
                  </h5>
                  <p>
                    Ajoutez un message visible par le destinataire sur sa
                    commande digitappro.
                  </p>
                </div>
              </div>
            </Modal.Header>
            <Modal.Body className="">
              <div className="modal-add-vehicule-body">
                <div className="modal-consult-reserve-body">
                  <Tchat messages={conversationArray} />
                </div>
                {props.selectedreserve?.status &&
                  props.selectedreserve?.status !== reserve_status.SOLVED && (
                    <div className="">
                      <GenericFomikTextInput
                        type="text"
                        name="message"
                        id="message"
                        placeholder="J'ai pu voir avec le livreur ce matin..."
                      >
                        Ajouter un commentaire (optionnel)
                      </GenericFomikTextInput>
                    </div>
                  )}
              </div>
              <div className="d-flex justify-content-center">
                <span className="error-body">{error}</span>
              </div>
            </Modal.Body>

            <Modal.Footer className="modal-answer-reserve-footere justify-content-center">
              <Button
                variant="secondary"
                className="modal-btn-footer"
                disabled={
                  isLoading ||
                  props.selectedreserve?.status === reserve_status.SOLVED
                }
                onClick={() => {
                  submitForm();
                }}
              >
                {props.selectedreserve?.status &&
                props.selectedreserve?.status !== reserve_status.SOLVED
                  ? "Traiter la réserve"
                  : "Déja traitée"}
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default ModalAnswerReserve;
