import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";

import lottie from "lottie-web";
import animationData from "./3075-delivery-van";

import {
  Container,
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  Badge,
} from "react-bootstrap";

import "../App/App.scss";
import "./Home.css";
import marker from "../EmptyListComponent/kekwgris.png";
import { VehiclesOnTour } from "../DashboardComponent/VehiclesOnTour/VehiclesOnTour";
import { Delivery } from "../DashboardComponent/Delivery/Delivery";
import { IssueBoard } from "../DashboardComponent/IssueBoard/IssueBoard";
import FacturationBoard from "../DashboardComponent/FacturationBoard/FacturationBoard";
import { logout } from "../../reducer/Action";
import { connect } from "react-redux";
import { Login } from "../Login/Login";
import { withRouter } from "react-router";
import { compose } from "redux";
import ResumeBoard from "../DashboardComponent/ResumeBoard/ResumeBoard";
import ReserveBoard from "../DashboardComponent/ReserveBoard/ReserveBoard";

export class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
      facturations: [
        {
          id: 1,
          date: "2020-07-02T00:00",
          truckCount: 42,
          price: 140,
        },
        {
          id: 1,
          date: "2020-06-02T00:00",
          truckCount: 23,
          price: 545,
        },
        {
          id: 1,
          date: "2020-05-02T00:00",
          truckCount: 45,
          price: 587,
        },
        {
          id: 1,
          date: "2020-04-02T00:00",
          truckCount: 903,
          price: 123,
        },
      ],
      openIssues: [],
      solvedIssues: [],
    };
  }

  componentDidMount() {}

  render() {
    return (
      <Container style={{ marginLeft: 0 }}>
        <Row>
          <Col>
            <Row>
              <Col>
                <div className={"header-fleet"}>
                  <h3>Dashboard</h3>
                </div>
              </Col>
            </Row>
            <Row>
              <VehiclesOnTour
                componentSize={5}
                tours={[]}
                lng={4.0333}
                lat={49.25}
                zoom={8}
                BASE_URL={this.props.BASE_URL}
              />
              <Delivery
                componentSize={7}
                tours={[]}
                BASE_URL={this.props.BASE_URL}
              />
              <IssueBoard
                componentSize={5}
                openIssues={this.state.openIssues}
                solvedIssues={this.state.solvedIssues}
                BASE_URL={this.props.BASE_URL}
                listType={"issue"}
              />
              <ResumeBoard componentSize={3} BASE_URL={this.props.BASE_URL} />
              <ReserveBoard componentSize={4} BASE_URL={this.props.BASE_URL} />
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    facturations: state.facturations,
    canceledBls: state.canceledBls,
    returnBls: state.canceledBls,
    BASE_URL: state.BASE_URL,
  };
};
const mapDispatchToProps = { logout };

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Home);
