export const tour_status = {
  ALL: "all",
  DONE: "done",
  LATE: "late",
  ISSUE: "issue",
  CREATED: "created",
  STARTED: "started",
  properties: {
    all: { display_name: "Toutes", color: "#079992" },
    done: { display_name: "Terminée", color: "#079992" },
    late: { display_name: "En retard", color: "#ffe085" },
    issue: { display_name: "Problème", color: "#ff9979" },
    started: { display_name: "Démarée", color: "#079992" },
    created: { display_name: "Créée", color: "#e55039" },
  },
};
