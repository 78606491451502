import React from 'react'
import "./log.scss"

export default function Log({log}) {
  return (
    <div className='log-container'>
      <div className='text'>{log}</div>
    </div>
  )
}
