export function computeTourStatus(rawStatus) {
  let resultStatus = "";
  switch (rawStatus) {
    case "CREATED":
    case "STARTED":
    case "DONE":
      resultStatus = "ok";
      break;
    case "LATE":
      resultStatus = "late";
      break;
    case "ISSUE":
      resultStatus = "issue";
      break;
    default:
      resultStatus = "";
      break;
  }
  return resultStatus;
}

export function computeTourSortStatusname(rawStatus) {
  let resultStatus = "";
  switch (rawStatus) {
    case "asc":
      resultStatus = "Ascendant";
      break;
    case "desc":
      resultStatus = "Descendant";
      break;
    default:
      resultStatus = "";
      break;
  }
  return resultStatus;
}
