import React from "react";
import StatusComponent from "../StatusComponent/StatusComponent";

import { Card, OverlayTrigger, Tooltip } from "react-bootstrap";

import "../../../App/App.scss";
import "./tourItemDashboard.scss";

import Skeleton from "react-loading-skeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";

const TourItemDashboard = (props) => {
  return (
    <div>
      <div
        className={`fleet-item-tour-dashboard ${
          props.selectedTourID && props.selectedTourID !== props.tour.tour_id
            ? "disabled"
            : ""
        }
        ${props.selectedTourID === props.tour.tour_id ? "selected" : ""}`}
        onClick={() =>
          props.selectedTourID === props.tour.tour_id ||
          !props.isMapReady ||
          props.isMapUpdating
            ? props.setselectedTourID(null)
            : props.setselectedTourID(props.tour.tour_id)
        }
      >
        <div as={Card.Header} className={"fleet-first-line-tour-dashboard"}>
          <div className={"fr"}>
            <span className={"fleet-item-title"}>
              Véhicule {props.tour.vehicule_key || <Skeleton />}{" "}
              <span className="fleet-item-title-id">
                N° {props.tour.tour_key || "tour_key"}
              </span>
            </span>
            <span className={"fleet-item-desc"}>
              {
                /*
                  Si la tournée n'est pas commencé, on affiche qu'aucune étape n'est en cours de livraison.
                  Si non : On va vérifier qu'il y'a bien des étapes encore à livrer
                    Si oui : On affiche l'étape en cours (retourné par le back, la première étape non livré restante)
                    Si non : On affiche rien
                  Si non : On affiche "Tournée terminée"
                */

                props.tour.status === "CREATED"
                  ? "Aucune étape en cours pour cette tournée"
                  : props?.tour?.vehicule_and_datas
                      ?.steps_handled_left_to_deliver > 0
                  ? props.tour.vehicule_and_datas?.current_step?.step_id
                    ? `${
                        props.tour.vehicule_and_datas?.current_step?.end_address
                          ?.street ?? ""
                      } ${
                        props.tour.vehicule_and_datas?.current_step?.end_address
                          ?.city ?? ""
                      } ${
                        props.tour.vehicule_and_datas?.current_step?.end_address
                          ?.zipcode ?? ""
                      }`
                    : null
                  : "Tournée terminée"
              }
            </span>
          </div>
          <div
            className={
              "status-container" +
              (props.tour?.issues_kpi?.number_of_open_issue === 0 &&
              props.tour?.issues_kpi?.number_of_solved_issue === 0
                ? " wider"
                : "")
            }
          >
            <StatusComponent
              status={props.tour.status}
              isLate={props.tour.is_late}
            />
          </div>

          <div className={"tour-issue"}>
            {props.tour?.issues_kpi?.number_of_open_issue > 0 ||
            props.tour?.issues_kpi?.number_of_solved_issue > 0 ? (
              <div>
                <OverlayTrigger
                  placement="left"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip id="button-tooltip">
                      {props.tour?.issues_kpi?.number_of_open_issue > 0
                        ? `Des réclamations sont ouvertes pour cette tournée. Vous pouvez les traiter en cliquant sur le bouton "Réclamations" en haut à droite.`
                        : `Toutes les réclamations de cette tournée ont été traitées.`}
                    </Tooltip>
                  }
                >
                  <span>
                    <FontAwesomeIcon
                      style={{ width: 18 }}
                      icon={
                        props.tour?.issues_kpi?.number_of_open_issue > 0
                          ? faWarning
                          : faExclamationCircle
                      }
                      color={
                        props.tour?.issues_kpi?.number_of_open_issue > 0
                          ? "#ff1400"
                          : "#5e5e5e"
                      }
                    />
                  </span>
                </OverlayTrigger>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

function areEqual(prevProps, nextProps) {
  return (
    prevProps.tour.status === nextProps.tour.status &&
    prevProps.tour.vehicule_and_datas?.current_step?.step_id ===
    nextProps.tour.vehicule_and_datas?.current_step?.step_id &&
    prevProps.isMapUpdating === nextProps.isMapUpdating &&
    prevProps.isMapReady === nextProps.isMapReady &&
    prevProps.selectedTourID === nextProps.selectedTourID &&
    prevProps.setselectedTourID === nextProps.setselectedTourID &&
    prevProps.tour.issues_kpi === nextProps.tour.issues_kpi
  );
}

export default React.memo(TourItemDashboard, areEqual);
