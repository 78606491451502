import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullhorn } from "@fortawesome/free-solid-svg-icons";
import bgdeco from "../../../../assets/images/background/background_decoration2x.png";
import chevrons from "../../../../assets/images/decoration/chevron@2x.png";
import "./dashboard-claim-modal.scss";
import SimpleBarReact from "simplebar-react";
import DashboardClaim from "../dashboard-claim/dashboard-claim";

function DashboardClaimModal({date, issues, FetchClaimData, show, base_url, onHide}) {

  function SolveIssue(issueId) {
    const url = new URL(base_url.concat("/issues/", issueId));
    const token = localStorage.getItem("fleetToken");

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);
    let myInit = {
      method: "PUT",
      headers: myHeaders,
      cache: "no-cache",
      body: JSON.stringify({
        status: "SOLVED",
      }),
    };

    fetch(url, myInit)
      .then((response) => {
        if (response.ok) {
          response.json().then((result) => {
            if (result.status === "Success" && result.isIssueUpdated) {
              FetchClaimData();
            }
          });
        } else {
          response.json().then((result) => {
            console.error("Mauvaise réponse du réseau", result);
          });
        }
      })
      .catch((error) => {
        console.error(
          "Il y a eu un problème avec l'opération fetch: " + error.message
        );
      });
  }

  /******
   *
   * Effects
   *
   ******/

  useEffect(() => {
    if (show) {
      FetchClaimData();
    }
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      dialogClassName="modal-70w detail-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <div className="claim-header-inner">
          <div>
            <FontAwesomeIcon icon={faBullhorn} className="btn-icon" />
          </div>
          <div>
            <h5>Réclamations enregistrées</h5>
            <p>
              Retrouvez et gérez toutes les réclamations traitées ou non depuis
              cet écran.
            </p>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="vehicle-detail-modal-body">
          <div className="claim-main-wrapper">
            <div className="left-part">
              <div className="claim-header">
                <div className="status-claim"></div>
                <h5>Réclamations ouvertes</h5>
              </div>
              <div className="claim-container">
                <div className="claim-container-inner">
                  <SimpleBarReact
                    forceVisible
                    style={{
                      maxHeight: "100%",
                      width: "100%",
                      padding: "0px 10px 0px 10px",
                    }}
                  >
                    {issues?.openIssues?.length > 0 ? (
                      issues?.openIssues?.map((claim, index) => (
                        <DashboardClaim
                          key={`${claim.issue_id}`}
                          status={"open"}
                          claim={claim}
                          solveIssueHandler={SolveIssue}
                        />
                      ))
                    ) : (
                      <div>
                        <p className="no-data-claim">
                          Aucune réclamation ouverte pour la date du{" "}
                          {date?.toLocaleDateString("fr-FR", {
                            weekday: "long",
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          })}
                        </p>
                      </div>
                    )}
                  </SimpleBarReact>
                </div>
              </div>

              <img
                src={bgdeco}
                alt="background_decoration_fleet"
                className="bg-deco-fleet"
              />
            </div>
            <div className="mid-part">
              <img
                src={chevrons}
                alt="background_decoration_fleet"
                className="chevron-deco-fleet"
              />
            </div>
            <div className="right-part">
              <div className="claim-header">
                <div className="status-claim"></div>
                <h5>Réclamations traitées</h5>
              </div>
              <div className="claim-container">
                <div className="claim-container-inner">
                  <SimpleBarReact
                    forceVisible
                    style={{
                      maxHeight: "100%",
                      width: "100%",
                      padding: "0px 10px 0px 10px",
                    }}
                  >
                    {issues?.solvedIssues?.length > 0 ? (
                      issues?.solvedIssues?.map((claim, index) => (
                        <DashboardClaim
                          key={`${claim.issue_id}`}
                          status={null}
                          claim={claim}
                        />
                      ))
                    ) : (
                      <div>
                        <p className="no-data-claim">
                          Aucune réclamation traitée pour la date du{" "}
                          {date?.toLocaleDateString("fr-FR", {
                            weekday: "long",
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          })}
                        </p>
                      </div>
                    )}
                  </SimpleBarReact>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default DashboardClaimModal;
