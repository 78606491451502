import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faExclamationCircle, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import "../App/App.scss";
import "./Forgot.css";
import { connect } from "react-redux";
import fleetBrand from "../App/FleetWhite.png";
import { toast } from "react-toastify";


class Forgot extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: "",
		};
	}

	handleEmailChange = (e) => {
		const email = e.target.value;
		this.setState({
			email: email
		});
	};

	sendForgottenPasswordEmail = (e) => {
		e.preventDefault();

		const url = new URL(this.props.BASE_URL + "/auth/send-forgot-password-token");
		let myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		let myInit = {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				email: this.state.email
			}),
			cache: "no-cache"
		};

		fetch(url, myInit)
			.then((response) => {
				if (response.ok) {
					response.json().then((result) => {
						toast.success(result?.message)
					});
				} else if (response.status === 400) {
					response.json().then((result) => {
						if (result?.errors[0]?.param === "email") {
							toast.warn("Votre email ne semble pas valide.")
						}
						else{
							toast.error("Une erreur s'est produite.")
						}
					});
				}
				else{
					toast.error("Une erreur s'est produite.")
				}
			})
			.catch(function (error) {
				toast.error("Une erreur s'est produite.")
			});
	};

	render() {
		return (
			<div style={{ height: '100%' }}>
				<Row className="align-items-center h-100">
					<Col lg={6} className="d-flex align-items-center">
						<div className="container-form">
							<h2 className="welcome">Demande de réinitialisation <br /> de votre mot de passe</h2>
							<p className="p-info">Saisissez l'adresse e-mail associé à votre compte. Nous vous <br /> enverrons
								un lien par e-mail redirigeant sur une autre page de <br />notre site,
								vous permettant de réinitialiser votre mot de passe.</p>
							<Form>
								<Form.Label className="label">Adresse email</Form.Label>
								<Form.Control
									className="custom-input-forgot"
									type="email"
									placeholder="Votre adresse email"
									onChange={(e) => this.handleEmailChange(e)}
									defaultValue={this.state.email}
									aria-describedby="basic-addon1"
								/>
								<div style={{ width: '100%' }}>
									<Button
										className={"send-btn mt-4"}
										variant="none"
										type="submit"
										onClick={(e) => this.sendForgottenPasswordEmail(e)}
										block="true"
									>
										Envoyer le lien de réinitialisation
									</Button>
									<Link to="/login">
										<p className="back-login mt-3">Retour à la page de connexion</p>
									</Link>
								</div>
							</Form>
						</div>
					</Col>
					<Col lg={6} className="h-100 d-sm-none d-lg-block" style={{ backgroundColor: '#079992' }}>
						<div className="background-img d-flex align-items-center justify-content-center">
							<img src={fleetBrand} alt="logo" className="logo-login" />
						</div>
					</Col>
				</Row>
			</div>
		);
	}
}

const mapStateToProps = (state /*, ownProps*/) => {
	return {
		BASE_URL: state.BASE_URL
	};
};

export default connect(mapStateToProps)(Forgot);
