export const actions = {
  AUTHENTICATE: "AUTHENTICATE",
  CONFIG: "CONFIG",
  LOGOUT: "LOGOUT",
  SET_TOURS: "SET_TOURS",
  SET_STEPS_FOR_A_TOUR: "SET_STEPS_FOR_A_TOUR",
  SET_BLS_FOR_A_TOUR: "SET_BLS_FOR_A_TOUR",
  VEHICLES: "VEHICLES",
  SET_VEHICLE_PIN: "SET_VEHICLE_PIN",
  SET_NAV_WIDTH: "SET_NAV_WIDTH",
};

export function authenticate(token) {
  return { type: actions.AUTHENTICATE, token: token };
}
export function setConfiguration(config) {
  return { type: actions.CONFIG, config: config };
}

export function logout() {
  return { type: actions.LOGOUT };
}

export function setTours(tours) {
  return { type: actions.SET_TOURS, tours: tours };
}
export function setVehicles(vehicles) {
  return { type: actions.VEHICLES, vehicles: vehicles };
}
export function setStepsForATour(tour_id, steps) {
  return { type: actions.SET_STEPS_FOR_A_TOUR, tour_id: tour_id, steps: steps };
}
export function setBlsForATour(tour_id, step_id, bls) {
  return {
    type: actions.SET_BLS_FOR_A_TOUR,
    tour_id: tour_id,
    step_id: step_id,
    bls: bls,
  };
}
export function setVehiclePin(vehicle_id, pin) {
  return { type: actions.SET_VEHICLE_PIN, vehicle_id: vehicle_id, pin: pin };
}

export function setNavWidth(nav_width) {
  return { type: actions.SET_NAV_WIDTH, nav_width: nav_width };
}
