import React, { Component } from "react";
import { Badge, Col, Container, Row } from "react-bootstrap";
import mapboxgl from "mapbox-gl";
import { Link } from "react-router-dom";

mapboxgl.accessToken =
  "pk.eyJ1IjoiYmF6YW0iLCJhIjoiY2oyMGo2YWM4MDAzMDMzcnZuenJ2MGF5MCJ9.YV9qxda8pOMxuy9VxN_zbA";

let vehiclesKPIInterval;
export class VehiclesOnTour extends Component {
  constructor(props) {
    super(props);
    this.state = {
      componentSize: props.componentSize,
      tours: props.tours,
      okStatusCount: 0,
      lateStatusCount: 0,
      issueStatusCount: 0,
      vehiclesOnTourCount: 0,
    };
    this._isMounted = false;
  }

  fetchKPI = () => {
    const url = new URL(this.props.BASE_URL.concat("/tours/KPI"));
    const token = localStorage.getItem("fleetToken");

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);
    let myInit = { method: "GET", headers: myHeaders, cache: "no-cache" };

    // console.log("GET request @ ",  this.props.BASE_URL.concat('/tours/KPI'));
    fetch(url, myInit)
      .then((response) => {
        if (response.ok) {
          response.json().then((result) => {
            if (result.status === "Success") {
              this._isMounted &&
                this.setState({
                  okStatusCount: result.KPI.OK,
                  lateStatusCount: result.KPI.Late,
                  issueStatusCount: result.KPI.Issue,
                });
            }
          });
        } else {
          console.log("Mauvaise réponse du réseau");
          response.json().then(function (result) {
            // console.log("result", result);
          });
        }
      })
      .catch(function (error) {
        // console.log('Il y a eu un problème avec l\'opération fetch: ' + error.message);
      });
  };

  componentDidMount() {
    this._isMounted = true;

    this._isMounted && this.fetchKPI();
    vehiclesKPIInterval = setInterval(() => {
      this._isMounted && this.fetchKPI();
    }, 10000);

    // animObj = lottie.loadAnimation({
    //     container: this.animBox, // the dom element that will contain the animation
    //     renderer: 'svg',
    //     loop: true,
    //     autoplay: true,
    //     animationData: animationData // the path to the animation json
    // });
  }

  componentWillUnmount() {
    // Lors du déchargement de la page, on de-register le setInterval qui va fetch les KPI de véhicules.
    this._isMounted = false;

    clearInterval(vehiclesKPIInterval);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.state.okStatusCount !== prevState.okStatusCount ||
      this.state.lateStatusCount !== prevState.lateStatusCount ||
      this.state.issueStatusCount !== prevState.issueStatusCount
    ) {
      this.setState({
        vehiclesOnTourCount:
          this.state.okStatusCount +
          this.state.lateStatusCount +
          this.state.issueStatusCount,
      });
    }
  }

  render() {
    return (
      <Col xs={this.state.componentSize}>
        <div className="card-fleet">
          <div className="tootip-card-fleet">
            {/*<OverlayTrigger*/}
            {/*key={"right"}*/}
            {/*placement={"right"}*/}
            {/*overlay={*/}
            {/*<Tooltip id={`tooltip-${"right"}`}>*/}
            {/*Tooltip on <strong>{"right"}</strong>.*/}
            {/*</Tooltip>*/}
            {/*}*/}
            {/*>*/}
            {/*<FontAwesomeIcon icon={faInfoCircle}/>*/}
            {/*</OverlayTrigger>*/}
          </div>
          <Container fluid>
            <Row>
              <Col xs={9}>
                <h4>
                  Véhicules en tournée{" "}
                  <Badge bg="secondary">{this.state.vehiclesOnTourCount}</Badge>
                </h4>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className={"card-fleet-number-wrapper"}>
                  <div
                    className={"card-fleet-number-container"}
                    style={{ backgroundColor: "#079992" }}
                  >
                    <Link to={"/tour?status=ok"}>
                      <span className={"card-fleet-number-container-number"}>
                        {this.state.okStatusCount}
                      </span>
                      <span className={"card-fleet-number-container-label"}>
                        Ok
                      </span>
                    </Link>
                  </div>
                  <div
                    className={"card-fleet-number-container-2"}
                    style={{ backgroundColor: "#fa983a" }}
                  >
                    <Link to={"/tour?status=late"}>
                      <span className={"card-fleet-number-container-number"}>
                        {this.state.lateStatusCount}
                      </span>
                      <span className={"card-fleet-number-container-label"}>
                        En retard
                      </span>
                    </Link>
                  </div>
                  <div
                    className={"card-fleet-number-container-2"}
                    style={{ backgroundColor: "#e55039" }}
                  >
                    <Link to={"/tour?status=issue"}>
                      <span className={"card-fleet-number-container-number"}>
                        {this.state.issueStatusCount}
                      </span>
                      <span className={"card-fleet-number-container-label"}>
                        Problème
                      </span>
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>

          <div className={"card-fleet-animation-container"}>
            <div
              style={{ margin: "0 auto" }}
              ref={(ref) => (this.animBox = ref)}
            ></div>
          </div>
        </div>
      </Col>
    );
  }
}

export default VehiclesOnTour;
