import React, { Component } from "react";

import "./EmptyListComponent.css";
import "../App/App.scss";
import componentImg from "./earth.png";
import componentImg2 from "./truck.png";

import { setStepsForATour, setTours } from "../../reducer/Action";
import connect from "react-redux/es/connect/connect";

export class EmptyListComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  componentDidMount() {
    //this.setState((state, props) => ({
    //    isActive: props.isActive,
    //    status: props.status
    //}));
  }

  componentWillUnmount() {}

  render() {
    return (
      <div className={"fleet-item"}>
        <div className={"no-item"}>
          <img
            src={this.props.type === "véhicule" ? componentImg2 : componentImg}
            alt={"illustration-no-item"}
          />
          <div className={"title-no-item"}>
            Pas de {this.props.type} disponible.
          </div>
          <div className={"desc-no-item"}>
            {this.props.type === "véhicule" ? "Aucun" : "Aucune"}{" "}
            {this.props.type} correpondant à votre recherche n'a été trouvée.
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    BASE_URL: state.BASE_URL,
  };
};

const mapDispatchToProps = { setStepsForATour };

export default connect(mapStateToProps, mapDispatchToProps)(EmptyListComponent);
