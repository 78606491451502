import './CardsContractLoader.css'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard } from "@fortawesome/free-regular-svg-icons";
import SkeletonLoader from "tiny-skeleton-loader-react";

const CardContract = ({ noBorderBottom}) => {
  return (
    <div
      
      className="list-container-item"
      style={noBorderBottom ? { borderBottom: 'none' } : {}}
    >
      <div className="icon-container">
        <SkeletonLoader height={"44px"} width={"44px"} />
      </div>
      <div
        className="text-container"
      >
        <span className="title">
           <SkeletonLoader height={"18px"} width={"92px"} />
        </span>
        <span className="description" >
          <SkeletonLoader height={"18px"} width={"101px"} />
        </span>
      </div>
      <div className="text-container" >
        <span className="title">
          <SkeletonLoader height={"18px"} width={"50px"} />
        </span>
        <span className="description" >
          <SkeletonLoader height={"18px"} width={"126px"} />
        </span>
      </div>
      <div className="text-container">
        <span className="title">
          <SkeletonLoader height={"18px"} width={"74px"} /> 
        </span>
        <span className="description">
          <SkeletonLoader height={"18px"} width={"149px"} />
        </span>
      </div>
      <div className="text-container">
        <span className="title">
          <SkeletonLoader height={"18px"} width={"71px"} />
        </span>
        <span className="description">
          <SkeletonLoader height={"18px"} width={"37px"} />
        </span>
      </div>
      <div className="text-container ml-2" style={{  textTransform: "none" }}>
          <SkeletonLoader height={"20px"} width={"52px"} />
      </div>
      <div className="text-container ml-2 justify-content-end align-items-center d-flex flex-row gap-1" style={{ flex: '0 0 170px', textTransform: "none" }}>
          <SkeletonLoader height={"30px"} width={"87px"} />
      </div>
      
    </div>
  );
};

export default CardContract;
