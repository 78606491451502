import React from "react"
import ContentLoader from "react-content-loader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList, faStickyNote} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";


function IssueBoardList(props){
    if(props.list && props.list.length > 0){
        return props.list.map((value, index) => {
            return (
                <div key={index} className={"issues-return-container"}>
                    <div className={"decoration"}>
                        <span className={"title-overlay"}></span>
                        <span className={"bgc-overlay"}></span>

                        <span className={"left-ticket"}></span>
                        <span className={"right-ticket"}></span>
                    </div>
                    <Link to={"/tour/"+value.tour_id}>
                        <span className={"fleet-item-desc-light"} style={{position:'relative', zIndex:1}}><FontAwesomeIcon icon={faList}/> {value.delivery_detail_key ? value.delivery_detail_key : "BL 00000000"}</span>
                        <span className={"fleet-item-desc2-light"} style={{position:'relative', zIndex:1}}><FontAwesomeIcon icon={faStickyNote}/> {value.issue_reason_message ? value.issue_reason_message : "n.c"}</span>
                    </Link>
                    <button className={"btn fleet-item-generic-button-small"} style={{position:'relative', zIndex:1,marginTop: 20, display: (props.status === 'open' ? 'block' : 'none')}} onClick={ () => props.solveIssueHandler( value.issue_id ) } disabled={props.isLoading} >Traiter</button>
                </div>
            )
        })
    }else{
        return (
            <span className="no-bl">Aucun BL non livré.</span>
        );

    }
}

export default IssueBoardList
