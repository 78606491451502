import { Redirect, Route } from "react-router-dom";
import React from "react";
// import { connect } from "react-redux";
// import { authenticate } from "../../reducer/Action";

export default function PrivateRoute({ children: children, fakeAuth, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        checkAuth() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export const checkAuth = () => {
  return localStorage.getItem("fleetToken");
};

// const mapStateToProps = (state /*, ownProps*/) => {
//   return {
//     isAuthenticated: state.auth.isAuthenticated,
//   };
// };
// const mapDispatchToProps = { authenticate };

// export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
