import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faSort, faTrash } from "@fortawesome/free-solid-svg-icons";

import { Row, Col, Button, Dropdown, Spinner } from "react-bootstrap";

import memoize from "memoize-one";

import "../../App/App.scss";
import "./Tour.css";

import TourItemList from "../../TourItemList/TourItemList";
import connect from "react-redux/es/connect/connect";
import { tour_status } from "../../../constants/tour_status";
import { computeTourSortStatusname } from "../../../utils/tours";

export class Tour extends Component {
  /*******
   *
   * States
   *
   *******/

  constructor(props) {
    super(props);
    this.state = {
      filterByID: null,
      isFiltering: true,
      isOrdering: true,
      isSorting: true,
      isLoading: false, // initial loading tours
      isLoadingMore: false, // loading more tours
      isLoadingSearch: false, // loading tour after user type in the search bar
      order: {
        displayName: computeTourSortStatusname("desc"),
        status: "desc",
      },
      filter: {
        displayName: tour_status?.properties["all"]?.display_name,
        status: "all",
      },
      localTours: [],
      localToursCount: 0,
      endDate: null,
      isReachingEnd: false,
      searchValue: "",
      isTyping: false,
      exactMatch: null,
      usedBlID: false,
    };
  }

  /*******
   *
   * Lifecycle
   *
   *******/
  async componentDidMount() {
    this.timer = null;

    //get the tour by id if contained in the url
    const { match } = this.props;

    // get all steps for each tour
    this.handleChangeSort(null, "desc");

    //set the correct status filter if contained in the url
    if (this.props.status == null) {
      this.handleChange(null, tour_status.ALL);
    } else {
      this.handleChange(null, this.props.status);
    }
    this.fetchToursAndFilter(match?.params?.id ? match.params.id : null);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props?.match?.params?.id !== prevProps?.match?.params?.id) {
      this.fetchToursAndFilter(
        this.props?.match?.params?.id ? this.props?.match.params.id : null
      );
    }

    if (this.state.localTours !== prevState.localTours) {
      this.setState({
        localToursCount: this.state.localTours?.length || 0,
      });
    }
    if (
      this.state.order?.status !== prevState.order?.status ||
      this.state.filter?.status !== prevState.filter?.status
    ) {
      this.filteringFunction(this.state.filter, this.state.order);
    }

    if (
      this.props.location?.state?.blId &&
      this.props.location?.state?.blId !== this.state.searchValue &&
      !this.state.usedBlID
    ) {
      this.setState({ usedBlID: true });
      this.handleSearchBar(this.props.location?.state?.blId);
    }
  }

  /*******
   *
   * Methods
   *
   *******/

  async fetchToursAndFilter(_id) {
    this.setState({
      isLoading: true,
    });
    if (!_id) {
      await this.getTourFilter(); // this will get all tours dates for pagination purpose and store it in "filters" state
      await this.getTourPaginated(); // get all tours corresponding to the stored dates and store them to localTours state
    } else {
      await this.getTourByID(_id);
    }

    setTimeout(() => this.setState({ isLoading: false }), 100);
  }

  getTourByID = (_id) => {
    let loadingState = {
      isLoadingMore: true,
      isReachingEnd: false,
    };

    this.setState(loadingState);

    const url = new URL("/tours/" + _id, this.props.BASE_URL);

    const token = localStorage.getItem("fleetToken");

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);
    let myInit = { method: "GET", headers: myHeaders, cache: "no-cache" };

    console.log("GET request @ ", url.toString());
    return fetch(url, myInit)
      .then((response) => {
        if (response.ok) {
          response.json().then((result) => {
            if (result.status === "Success") {
              // otherwise, set the result as first page index
              this.setState((prevState, props) => ({
                isLoadingMore: false,
                isLoadingSearch: false,
                localTours: [result.tour] || [],
                isReachingEnd: true,
                filterByID: true,
              }));
            } else if (result.status === "Failed") {
              this.setState({
                isLoadingMore: false,
                isLoadingSearch: false,
                localTours: [],
                filterByID: true,
              });
            } else {
              this.setState({
                isLoadingMore: false,
                isLoadingSearch: false,
                filterByID: true,
              });
            }
          });
        } else {
          this.setState({
            isLoadingSearch: false,
            isLoadingMore: false,
            filterByID: true,
          });
          console.log("Mauvaise réponse du réseau");
          response.json().then(function (result) {
            console.log("result", result);
          });
        }
      })
      .catch((error) => {
        this.setState({
          isLoadingMore: false,
          isLoadingSearch: false,
          filterByID: true,
        });
        console.log(
          "Il y a eu un problème avec l'opération fetch: " + error.message
        );
      });
  };

  getTourPaginated = () => {
    let loadingState = {
      isLoadingMore: true,
      isReachingEnd: false,
      exactMatch: null,
    };

    // this will permit to show an indicator that the search query is loading
    if (this.state.searchValue !== "") {
      loadingState["isLoadingSearch"] = true;
    }

    this.setState(loadingState);

    let params = {
      date: this.state.endDate
        ? this.state.endDate
        : new Date().toISOString().slice(0, 10),
    };

    // if the user is searching, we need to add the search value to the params
    if (this.state.searchValue !== "") {
      params["query"] = this.state.searchValue;
      params["fieldToSearch"] = "blKey";
    }

    // if the user is filtering, we need to add the filtering status to the params
    if (this.state.filter?.status && this.state.filter?.status !== "all") {
      params["statusFilter"] = this.state.filter?.status.toUpperCase();
    }

    // if the user is ordering, we need to add the ordering status to the params
    if (this.state.order?.status) {
      params["order"] = this.state.order?.status.toUpperCase();
    }

    const url = new URL("/tours", this.props.BASE_URL);

    // append GET params to url
    for (let x in params) {
      url.searchParams.append(x, params[x]);
    }

    const token = localStorage.getItem("fleetToken");

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);
    let myInit = { method: "GET", headers: myHeaders, cache: "no-cache" };

    // console.log("GET request @ ", url.toString());
    return fetch(url, myInit)
      .then((response) => {
        if (response.ok) {
          response.json().then((result) => {
            if (result.status === "Success") {
              let actualDateIndex = null;

              // get the index of the actual date in the filters array
              if (this.state.filters) {
                actualDateIndex = this.state.filters.indexOf(
                  this.state.endDate
                );
              }

              // if the page index is not the first one or undefined, append the result data to the list
              if (actualDateIndex !== -1 && actualDateIndex !== 0) {
                this.setState((prevState, props) => ({
                  isLoadingMore: false,
                  isLoadingSearch: false,
                  localTours: [...prevState.localTours, ...result.tours],
                  filterByID: null,
                }));
              } else {
                // otherwise, set the result as first page index
                this.setState((prevState, props) => ({
                  isLoadingMore: false,
                  isLoadingSearch: false,
                  localTours: result.tours || [],
                  filterByID: null,
                }));
                // if the index is the last one, set isReachingEnd to true
                if (
                  !this.state.isReachingEnd &&
                  actualDateIndex + 1 >= this.state.filters.length - 1
                ) {
                  this.setState({
                    isReachingEnd: true,
                  });

                  // if there is only one result & the search query is not empty, set exactMatch to true
                  if (result.tours?.length === 1 && params.query) {
                    this.setState({
                      exactMatch: result.tours[0].tour_id,
                    });
                  }
                }
              }
            } else if (result.status === "Failed") {
              this.setState({
                isLoadingMore: false,
                isLoadingSearch: false,
                localTours: [],
                filterByID: null,
              });
            } else {
              this.setState({
                isLoadingMore: false,
                isLoadingSearch: false,
                filterByID: null,
              });
            }
          });
        } else {
          this.setState({
            isLoadingSearch: false,
            isLoadingMore: false,
            filterByID: null,
          });
          console.log("Mauvaise réponse du réseau");
          response.json().then(function (result) {
            // console.log("result", result);
          });
        }
      })
      .catch((error) => {
        this.setState({
          isLoadingMore: false,
          isLoadingSearch: false,
        });
        console.log(
          "Il y a eu un problème avec l'opération fetch: " + error.message
        );
      });
  };

  getTourFilter() {
    let _params = {};

    // this will permit to show an indicator that the search query is loading
    if (this.state?.searchValue !== "") {
      _params = {
        query: this.state?.searchValue,
      };
    }

    // if the user is filtering, we need to add the fitlering status to the params
    if (this.state.filter?.status && this.state.filter?.status !== "all") {
      _params["statusFilter"] = this.state.filter?.status.toUpperCase();
    }

    // if the user is ordering, we need to add the ordering status to the params
    if (this.state.order?.status) {
      _params["order"] = this.state.order?.status.toUpperCase();
    }

    const url = new URL("/filters", this.props.BASE_URL);
    for (let x in _params) {
      url.searchParams.append(x, _params[x]);
    }
    const token = localStorage.getItem("fleetToken");

    if (this.state.localTours.length === 0) {
      this.setState({
        isLoadingMore: true,
      });
    }

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);
    let myInit = { method: "GET", headers: myHeaders, cache: "no-cache" };

    console.log("GET request @ ", url.toString());
    return fetch(url, myInit)
      .then((response) => {
        if (response.ok) {
          return response.json().then((result) => {
            if (result.status === "Success") {
              this.setState((prevState, props) => ({
                filters: result?.filters?.dates,
                endDate:
                  result?.filters?.dates && result?.filters?.dates[0]
                    ? result?.filters?.dates[0]
                    : null,
                startDate:
                  result?.filters?.dates && result?.filters?.dates[0]
                    ? result?.filters?.dates[0]
                    : null,
              }));
              return { filters: result?.filters?.dates };
              //this.props.setTours(result.tours);
            }
          });
        } else {
          console.log("Mauvaise réponse du réseau");
          response.json().then(function (result) {
            console.log("result", result);
          });
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        console.log(
          "Il y a eu un problème avec l'opération fetch: " + error.message
        );
      });
  }

  filteringFunction = memoize(async (filter, order) => {
    this.fetchToursAndFilter();
  });

  /*******
   *
   * Handlers
   *
   *******/

  seeMoreHandler() {
    // incremente l'index et la end_date ssi il existe
    let actualDateIndex = null;

    // get the index of the actual date in the filters array
    if (this.state.filters) {
      actualDateIndex = this.state.filters.indexOf(this.state.endDate);
    }
    // if the index is not the last one or second to last, increment it, else set isReachingEnd to true
    if (actualDateIndex !== -1 && this.state.filters) {
      if (actualDateIndex + 1 <= this.state.filters.length - 1) {
        this.setState(
          {
            endDate: this.state.filters[actualDateIndex + 1],
          },
          () => {
            this.getTourPaginated();
          }
        );
      } else {
        if (!this.state.isReachingEnd) {
          this.setState({
            isReachingEnd: true,
          });
        }
      }

      // if the index is the last one, set isReachingEnd to true
      if (
        !this.state.isReachingEnd &&
        actualDateIndex + 1 >= this.state.filters.length - 1
      ) {
        this.setState({
          isReachingEnd: true,
        });
      }
    }
  }

  handleChangeSort(e, _status) {
    this.setState({
      order: {
        displayName: computeTourSortStatusname(_status),
        status: _status,
      },
      isOrdering: true,
    });
  }

  handleChange(e, _status) {
    this.setState({
      filter: {
        displayName: tour_status?.properties[_status]?.display_name,
        status: _status,
      },
      isFiltering: _status !== tour_status.ALL,
    });
  }

  handleSearchBar(value) {
    // const target = e.target;
    // const value = target.value;

    clearTimeout(this.timer);

    this.setState({
      searchValue: value,
      isTyping: true,
    });

    this.timer = setTimeout(async () => {
      this.setState({
        isTyping: false,
      });

      if (value === "") {
        this.setState(
          {
            localTours: [],
            endDate:
              this.state.filters && this.state.filters[0]
                ? this.state.filters[0]
                : new Date().toISOString().slice(0, 10),
          },
          () => {
            this.fetchToursAndFilter();
          }
        );
      } else {
        this.fetchToursAndFilter();
      }
    }, 600);

    this.setState({ searchValue: value });
  }

  handleDeleteTourByIDFilter() {
    this.setState({
      filterByID: false,
    });
    this.props.history.push("/tour");

    this.fetchToursAndFilter();
  }

  /*******
   *
   * Render
   *
   *******/

  render() {
    return (
      <div className="generic-page-wrapper">
        <div className="header-fleet">
          <div className="header-fleet-inner">
            <div>
              <div className="header-fleet-title">
                <h3>Liste des tournées </h3>
                <span className="tour-header-number badge">
                  {this.state.localToursCount}
                </span>
              </div>

              <h4>Retrouvez la liste de vos tournées et leurs détails.</h4>
            </div>
            <div>
              <div className={"filter-status-wrapper"}>
                <div
                  className={"filter-status-wrapper-child"}
                  id={"filter-status-wrapper"}
                >
                  {this.state.filterByID ? (
                    <Button
                      variant=""
                      className="btn-fleet-header"
                      onClick={() => this.handleDeleteTourByIDFilter()}
                    >
                      <FontAwesomeIcon icon={faTrash} className="btn-icon" />
                      Supprimer le filtre actuel
                    </Button>
                  ) : null}

                  {!this.state.filterByID ? (
                    <>
                      <div className="btn-group btn-group-toggle me-2">
                        <Dropdown className={" dropdown-filter"}>
                          <Dropdown.Toggle
                            variant=""
                            id="dropdown-basic"
                            className={"btn-fleet-header btn"}
                          >
                            <FontAwesomeIcon
                              icon={faSort}
                              className="btn-icon"
                            />
                            {this.state.order
                              ? this.state.order.displayName
                              : "undefined"}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              className="fleet-header-drowpdown-item"
                              onClick={(e) => this.handleChangeSort(e, "asc")}
                            >
                              Ascendant
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="fleet-header-drowpdown-item"
                              onClick={(e) => this.handleChangeSort(e, "desc")}
                            >
                              Descendant
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <div
                        className="btn-group btn-group-toggle "
                        style={{
                          display: !this.state.filterByID ? "block" : "none",
                        }}
                      >
                        <Dropdown className={" dropdown-filter"}>
                          <Dropdown.Toggle
                            variant=""
                            id="dropdown-basic"
                            className={"btn-fleet-header btn"}
                          >
                            <FontAwesomeIcon
                              icon={faFilter}
                              className="btn-icon"
                            />
                            {this.state.filter
                              ? this.state.filter.displayName
                              : "undefined"}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              className="fleet-header-drowpdown-item"
                              onClick={(e) =>
                                this.handleChange(e, tour_status.ALL)
                              }
                            >
                              {
                                tour_status?.properties[tour_status.ALL]
                                  ?.display_name
                              }
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="fleet-header-drowpdown-item"
                              onClick={(e) =>
                                this.handleChange(e, tour_status.CREATED)
                              }
                            >
                              {
                                tour_status?.properties[tour_status.CREATED]
                                  ?.display_name
                              }
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="fleet-header-drowpdown-item"
                              onClick={(e) =>
                                this.handleChange(e, tour_status.STARTED)
                              }
                            >
                              {
                                tour_status?.properties[tour_status.STARTED]
                                  ?.display_name
                              }
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="fleet-header-drowpdown-item"
                              onClick={(e) =>
                                this.handleChange(e, tour_status.ISSUE)
                              }
                            >
                              {
                                tour_status?.properties[tour_status.ISSUE]
                                  ?.display_name
                              }
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="fleet-header-drowpdown-item"
                              onClick={(e) =>
                                this.handleChange(e, tour_status.DONE)
                              }
                            >
                              {
                                tour_status?.properties[tour_status.DONE]
                                  ?.display_name
                              }
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Row>
          <Col>
            <div className={"search-bar-wrapper"}>
              <input
                type="text"
                value={this.state.searchValue}
                onChange={(e) => this.handleSearchBar(e.target.value)}
                placeholder="Rechercher par n° de BL..."
                className="fleet-search-tour"
                disabled={this.state.filterByID}
              />
              {this.state.isTyping || this.state.isLoadingSearch ? (
                <Spinner
                  animation="grow"
                  size="sm"
                  className="spinner-search"
                />
              ) : null}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <TourItemList
              base_url={this.props.BASE_URL}
              isLoading={this.state.isLoading}
              isLoadingMore={this.state.isLoadingMore}
              tours={this.state.localTours}
              exactMatch={this.state.exactMatch}
              searchValue={this.state.searchValue}
            />
            <div className="text-center mt-4 mb-5">
              <Button
                variant=""
                className="fleet-item-btn-seemore"
                onClick={() => this.seeMoreHandler()}
                disabled={
                  this.state.isReachingEnd ||
                  this.state.isLoadingMore ||
                  this.state.isLoading ||
                  this.state.localTours?.length === 0
                }
              >
                {this.state.isReachingEnd
                  ? "Plus de tournées"
                  : this.state.isLoadingMore
                  ? "Chargement..."
                  : "Voir +"}
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    BASE_URL: state.BASE_URL,
  };
};

export default connect(mapStateToProps)(Tour);
