import React, { useState, useRef, useEffect } from "react";
import { Modal, Button, Card } from "react-bootstrap";
import "./modal-add-platform.scss";

import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";

const ModalAddPlateform = (props) => {
  const [currentPlatform, setCurrentPlatform] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const mapContainerRef = useRef(null);
  const map = useRef(null);

  /******
   *
   * functions
   *
   ******/

  const postPlatforms = () => {
    const url = new URL(props.BASE_URL + "/starting-platform");
    const token = localStorage.getItem("fleetToken");

    setIsLoading(true);

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);
    let myInit = {
      method: "POST",
      headers: myHeaders,
      cache: "no-cache",
      body: JSON.stringify({
        ...currentPlatform,
      }),
    };

    console.log("POST request @ ", props.BASE_URL + "/starting-platform");

    fetch(url, myInit)
      .then((response) => {
        if (response.ok) {
          setIsLoading(false);
          setError(null);
          props.onHide(true);
        } else {
          setIsLoading(false);
          setError("Une erreur est survenue");
          response.json().then(function (result) {
            console.log("result", result);
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(
          "Il y a eu un problème avec l'opération fetch: " + error.message
        );
      });
  };

  /******
   *
   * Effects
   *
   ******/

  useEffect(() => {
    if (map.current) return; // initialize map only once

    if (mapContainerRef.current) {
      map.current = new MapboxGeocoder({
        accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
        types: "address",
        countries: "FR,BE,DE,NL"
      });
      map.current.addTo(mapContainerRef.current);

      map.current.on("result", (result) => {
        if (result.result) {
          setCurrentPlatform({
            full_address: result.result.place_name,
            latitude: result.result.geometry.coordinates[1],
            longitude: result.result.geometry.coordinates[0],
          });
        }
      });
    }
  });

  return (
    <Modal
      size="lg"
      {...props}
      onShow={() => {
        setCurrentPlatform(null);
        setError(null);
        map.current = null;
      }}
    >
      <div className="background-modal-fleet-add-platform" />
      <Modal.Header className="modal-config-header" closeButton>
        <div className="claim-header-inner-modal-platform">
          <div>
            <h5>Ajouter une nouvelle plateforme</h5>
            <p>
              Saisissez l'adresse de la plateforme pour l'ajouter à votre liste
            </p>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className="modal-config-body-platform">
        <div className="background-grey-around">
          <div className={"fleet-platform-list-add"}>
            <div
              as={Card.Header}
              className={"fleet-first-line-tour-management-modal-platform"}
            >
              <div className={"hw-modal-platform d-flex justify-content-center align-items-center"}>
                <div className="rounded-background d-flex justify-content-center align-items-center" style={{ backgroundColor: "#079992" }}>
                  <div className="span-color" />
                </div>
              </div>
              <div className={"fr-modal-platform"}>
                <span className="fleet-item-title-id-modal-platform">
                  Plateforme N° {"X"}
                </span>
                <div className="geocoder-wrapper">
                  <div ref={mapContainerRef} className="geocoder" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <div>
        <p className="msg-error-modal-add-platform text-center">{error}</p>
      </div>
      <Modal.Footer className="modal-config-footer mx-auto">
        <Button
          variant="secondary"
          className="modal-btn-footer"
          onClick={() => {
            postPlatforms();
          }}
          disabled={currentPlatform === null || isLoading}
        >
          {isLoading ? "Chargement..." : "Ajouter la plateforme"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalAddPlateform;
