import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faComment,
  faList,
  faStickyNote,
} from "@fortawesome/free-solid-svg-icons";
import { Card } from "react-bootstrap";
import "./Bl.css";

import inArrow from "./BlAssets/in_arrow_v2.png";
import outArrow from "./BlAssets/out_arrow.png";

import pictoPhoto from "./BlAssets/photo.png";
import pictoSign from "./BlAssets/sign.png";
import pictoSignDisabled from "./BlAssets/signDisabled.png";
import Item from "../Item/Item";

export class Bl extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let blImgArrow;
    let pictoPhotoPlaceholder;
    let pictoSignPlaceholder;

    if (this.props.bl.delivery_type === "OUT") {
      blImgArrow = (
        <img
          src={outArrow}
          className={"img-illustration-fleet-bl"}
          alt={"out_arrow"}
        />
      );
    } else if (this.props.bl.delivery_type === "IN") {
      blImgArrow = (
        <img
          src={inArrow}
          className={"img-illustration-fleet-bl"}
          alt={"in_arrow"}
        />
      );
    } else {
      blImgArrow = null;
    }

    if (this.props.bl.is_dropable && !this.props.bl.is_present) {
      pictoPhotoPlaceholder = (
        <img
          src={pictoPhoto}
          className={"img-illustration-fleet-bl-sign"}
          alt={"picto_photo"}
        />
      );
    } else {
      //on affiche toujours le picto photo pendant le covid
      // pictoPhotoPlaceholder = <img src={pictoPhotoDisabled} className={"img-illustration-fleet-bl-sign"} alt={"picto_photo_disabled"} />
      pictoPhotoPlaceholder = (
        <img
          src={pictoPhoto}
          className={"img-illustration-fleet-bl-sign"}
          alt={"picto_photo"}
        />
      );
    }

    if (this.props.bl.is_present) {
      pictoSignPlaceholder = (
        <img
          src={pictoSign}
          className={"img-illustration-fleet-bl-sign"}
          alt={"picto_photo"}
        />
      );
    } else {
      pictoSignPlaceholder = (
        <img
          src={pictoSignDisabled}
          className={"img-illustration-fleet-bl-sign"}
          alt={"picto_sign_disabled"}
        />
      );
    }
    //let clientId = parseInt(localStorage.getItem("fleetClientId"));

    return (
      <div className={"fleet-item-bl-card"}>
        <Card.Header style={{ zIndex: 2 }}>
          <div className={"fleet-item-bl-wrapper"}>
            <div>
              {this.props.bl.proofs && this.props.bl.proofs?.length > 0
                ? this.props.bl.proofs.map((proof, index) => {
                    return (
                      <>
                        {this.props.bl.proofs?.length > 1 ? (
                          <span style={{ marginRight: "20px" }}>{`${
                            proof.doc_type === "PROOF_SIGN"
                              ? "Signature"
                              : "Photo"
                          } ${index + 1}`}</span>
                        ) : null}

                        <div className={"fleet-item-bl-images"}>
                          <div className={"fleet-item-bl-image-container"}>
                            <div className={"fleet-item-bl-image"}>
                              <a target="_blank" href={proof.url}>
                                <img
                                  src={proof.url}
                                  alt={`proof-bl-${this.props?.bl?.delivery_detail_key}`}
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })
                : null}
            </div>
            <div>
              <div
                className={
                  "fleet-item-bl-number" +
                  (this.props.bl.status === "RETURNED" ||
                  this.props.bl.status === "CANCELED"
                    ? "disabled-bl"
                    : "")
                }
              >
                {blImgArrow}
                <div style={{ marginLeft: 80 }}>
                  <FontAwesomeIcon icon={faList} />{" "}
                  {this.props.bl.delivery_detail_key}{" "}
                  {this.props.bl.status === "RETURNED" ? "(Retourné)" : ""}
                  {this.props.bl.status === "CANCELED" ? "(Non livré)" : ""}
                </div>
              </div>
              {this.props.bl.status === "CANCELED" ? (
                <span className={"fleet-item-desc2"}>
                  <FontAwesomeIcon icon={faStickyNote} />{" "}
                  {this.props.bl.issue.reason_id
                    ? this.props.bl.issue.reason_message
                    : "n.c"}
                </span>
              ) : null}

              {this.props.bl.reserve?.message ? (
                <span className={"fleet-item-desc3"}>
                  <FontAwesomeIcon icon={faComment} />{" "}
                  {this.props.bl.reserve
                    ? this.props.bl.reserve.message
                    : "n.c"}
                </span>
              ) : null}

              {this.props.bl.receiver1 || this.props.bl.receiver2 ? (
                <div style={{ marginLeft: 75 }}>
                  <span className={"fleet-item-desc4"}>{`Receveurs : ${
                    this.props.bl.receiver1 ? this.props.bl.receiver1 : ""
                  } ${
                    this.props.bl.receiver2 ? this.props.bl.receiver2 : ""
                  }`}</span>
                </div>
              ) : null}
            </div>
            <div className="picto-wrapper-bl">
              <div>{pictoPhotoPlaceholder}</div>
              <div>{pictoSignPlaceholder}</div>
            </div>
          </div>
          {this.props.bl?.items?.map((value, index) => {
            return <Item item={value} key={`item_${value?.item_id}`} />;
          })}
        </Card.Header>
      </div>
    );
  }
}

export default Bl;
