import React, { useState, useContext, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import StatusComponent from "../StatusComponent/StatusComponent";

import { Card } from "react-bootstrap";

import "../../../App/App.scss";
import "./tour-item-dashboard-loader.scss";

import SkeletonLoader from "tiny-skeleton-loader-react";

const TourItemDashboardLoader = (props) => {
  return (
    <div>
      <div className={`fleet-item-tour-dashboard-loader`}>
        <div as={Card.Header} className={"fleet-first-line-tour-dashboard"}>
          <div className={"hw"}></div>
          <div className={"fr"}>
            <span className={"fleet-item-title"}>
              <SkeletonLoader height={16} width={140} />{" "}
            </span>
            <span className={"fleet-item-desc"}>
              <SkeletonLoader height={12} width={"70%"} />
            </span>
          </div>
          <div className={"hwe-auto"}>
            <SkeletonLoader height={16} width={55} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(TourItemDashboardLoader);
