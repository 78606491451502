import React, { useState, useEffect, useRef } from "react";
import { Button, Col, Row, Modal, Card } from "react-bootstrap";
import "./ModalReserve.scss";

import { reserve_status } from "../../../../constants/reserve_status";
import Log from "../../../Log";
import Message from "../../../Message";
import { faClipboard } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProductReserve from "../../../ProductReserve";
import Select from "../../../Select";
import { useSelector } from "react-redux";
import BlReserve from "../../../BlReserve";

function ModalReserve({reserve_id, show, onHide}) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [comment, setComment] = useState("");
  const [events, setEvents] = useState([]);
  const [selectedBls, setSelectedBls] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [reserve, setReserve] = useState(null);
  
  const BASE_URL = useSelector((state) => state.BASE_URL);
  const TOKEN = localStorage.getItem("fleetToken");

  const BLS_NUMBER = reserve?.delivery_details_linked?.length
  const CREATION_DATE = new Date(reserve?.created_at)

  useEffect(() => {
    (async() => {
      if(show){
        await getReserve()
      }
      else{
        setIsOpen(false)
        setSelectedBls([])
        setReserve(null)
        setError("")
        setComment("")
        setEvents([])
      }
    })()
  }, [show])
  
  useEffect(() => {
    if (reserve) {
      let bls_already_linked = []
      reserve?.delivery_details_available?.forEach(bl => {
        if(bl?.is_already_linked){
          bls_already_linked.push(bl?.delivery_detail_key)
        }
      })
      setSelectedBls(bls_already_linked)

      let events = [];
      let creation_log = reserve?.logs?.filter(
        (element) => element?.type === 'CREATION_LOG'
      )[0];
      if (creation_log) {
        events.push({
          text: creation_log?.message,
          type: 'LOG',
        });
      }
      if (reserve?.message?.length) {
        events.push({
          text: reserve?.message,
          type: reserve?.type,
        });
      }
      let response_log = reserve?.logs?.filter(
        (element) => element?.type === 'ANSWERED_AT'
      )[0];
      if (response_log) {
        events.push({
          text: response_log?.message,
          type: 'LOG',
        });
      }
      if (reserve?.response?.length) {
        events.push({
          text: reserve?.response,
          type: 'RESPONSE',
        });
      }

      setEvents(events);
    }
  }, [reserve]);

  const getReserve = async() => {
    if (!BASE_URL) return;

    setIsLoading(true);
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", TOKEN);
    let myInit = {
      method: "GET",
      headers: myHeaders,
      cache: "no-cache",
    };

    try {
      const response = await fetch(BASE_URL + `/reserves_v2/${reserve_id}`, myInit);

      if (response.ok) {
        const result = await response.json();
        if (!result?.reserve) {
          throw new Error("reserve is not defined");
        }
        setReserve(result.reserve);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }
  
  const handleSubmitMethod = async () => {
    setIsLoading(true);

    if (!reserve_id) {
      throw new Error("Impossible to find the Reserve !");
    }

    let resultUpdateBls, resultSolve
    
    let have_to_update = false
    selectedBls.forEach(element => {
      if(!reserve?.delivery_details_linked?.map(bl => bl.delivery_detail_key)?.includes(element)){
        have_to_update = true
      }
    })
    
    if(have_to_update){
      try {
        const response = await updateBls();
        if (!response.ok) {
          const error = await response.json();
          setError(error.message)
          throw new Error(error.message || response.status);
        }
        resultUpdateBls = await response.json();
      } catch (error) {
        setError(error.toString())
      }
    }
    if(!have_to_update || resultUpdateBls?.has_been_updated){
      try {
        const response = await solveReserve();
        if (!response.ok) {
          const error = await response.json();
          throw new Error(error || response.status);
        }
        resultSolve = await response.json();
      } catch (error) {
        setError(error.toString())
      }
    }
    setIsLoading(false)
    if(resultSolve?.has_been_updated){
      onHide(true)
    }
  };
  
  const updateBls = async () => {
    const body = {};
    let selectedBlsIds = reserve?.delivery_details_linked?.filter(bl => selectedBls.includes(bl?.delivery_detail_key))?.map(bl => bl?.delivery_detail_id)
    if (selectedBlsIds?.length) {
      body["delivery_detail_ids"] = selectedBlsIds;
    }

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", TOKEN);
    let myInit = {
      method: "PUT",
      headers: myHeaders,
      cache: "no-cache",
      body: JSON.stringify(body),
    };

    return fetch(BASE_URL + `/reserves_v2/${reserve_id}/change-delivery-details`, myInit);
  };

  const solveReserve = async () => {
    const body = {};
    
    if (comment?.length) {
      body["response"] = comment;
    }

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", TOKEN);
    let myInit = {
      method: "PUT",
      headers: myHeaders,
      cache: "no-cache",
      body: JSON.stringify(body),
    };

    return fetch(BASE_URL + `/reserves_v2/${reserve_id}/solved`, myInit);
  };
  
  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="md"
      contentClassName="modal-content-reserve"
      onClick={() => setIsOpen(false)}
    >
      <Modal.Header className="modal-header-reserve" closeButton>
        <div className="claim-header-inner-modal">
          <div className="header-title">
            {`${reserve?.status !== reserve_status.SOLVED ? "Traiter r" : "R"}éserve ${reserve?.delivery_detail_key}`}
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className="modal-body-reserve">
        <div>
          <div className="head-section">
            <div className="left-part">
              <div className="icon-container">
                <div className="icon-wrapper">
                  <FontAwesomeIcon icon={faClipboard} height={19} className='select-none' color="#ABB5BF" width={19} />
                </div>
              </div>
              <div>
                <div className="bls-title">{`BL${reserve?.delivery_detail_key}${BLS_NUMBER > 1 ? ", +"+(BLS_NUMBER-1)+" BL"+(BLS_NUMBER-1 > 1 ? "s" : "") : ""}`}</div>
                <div className="reserve-date">
                  {`Créée le ${CREATION_DATE.toLocaleDateString("fr-FR", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })} à ${CREATION_DATE.toLocaleTimeString("fr-FR", {
                    hour: "2-digit", 
                    minute: "2-digit"
                  })}`}
                </div>
              </div>
            </div>
            <div className="reserve-status">
              <span className="badge" style={{color: reserve_status.properties[reserve?.status]?.color}}>
                {reserve_status.properties[reserve?.status]?.display_name}
              </span>
            </div>
          </div>

          {reserve?.status === reserve_status.SOLVED && (
            <div className="section">
              <div className="subtitle-reserve">BONS DE LIVRAISON</div>
              <div className="text-description-reserve">Retrouvez la liste des BLs concernés par la réserve.</div>

              <div style={{marginTop: "1rem"}}>
                {reserve?.delivery_details_linked?.map(bl => (
                  <BlReserve key={bl?.delivery_detail_id} bl={bl} /> 
                ))}
              </div>
            </div>
          )}

          <div className="section">
            <div className="subtitle-reserve">HISTORIQUE DE L'ÉCHANGE</div>
            <div className="text-description-reserve">Retrouvez l'entièreté de l'historique des échanges concernant cette réserve.</div>

            <div className="messages">
              {events.map(event => {
                return(
                  event?.type === "LOG" ? 
                    <Log log={event?.text} />
                    :
                    <Message message={event?.text} type={event?.type} />
                )
              })}
            </div>
          </div>

          {reserve?.products?.length > 0 && (
            <div className="section">
              <div className="subtitle-reserve">PRODUITS CONCERNÉS</div>
              <div className="text-description-reserve">Veuillez retrouver ci-dessous la liste des produits signalés par l'utilisateur pour réclamation :</div>

              <div style={{marginTop: "1rem"}}>
                {reserve?.products?.map(product => {
                  return(
                    <ProductReserve product={product} />
                  )
                })}
              </div>
            </div>
          )}

          {reserve?.status !== reserve_status.SOLVED && (
            <>
            <div className="section">
              <div className="subtitle-reserve">BONS DE LIVRAISON CONCERNÉS</div>
              <div className="text-description-reserve">Vous pouvez réassigner les BLs concernés par la réserve si besoin.</div>

              <div className="bls-select-container">
                <Select values={reserve?.delivery_details_available?.filter(bl => bl?.is_valid)?.map(bl => bl?.delivery_detail_key)} selectedValues={selectedBls} setSelectedValues={setSelectedBls} isOpen={isOpen} setIsOpen={setIsOpen} />
                <div className="bls-selected">{selectedBls?.length} BL concerné{selectedBls?.length > 1 ? "s" : ""}</div>
              </div>
            </div>

            <div className="section">
              <div className="subtitle-reserve">AJOUTER UN COMMENTAIRE (OPTIONNEL)</div>
              <div className="text-description-reserve">Ajoutez un message visible par le destinataire sur sa commande digitappro.</div>

              <textarea onChange={event => setComment(event.target.value)} value={comment} className="reserve-comment" placeholder="Votre message..." />
            </div>
            </>
          )}
        </div>
      </Modal.Body>

      <Modal.Footer className="modal-reserve-footer justify-content-center">
        {error?.length > 0 && (
          <div className="error">{error}</div>
        )}
        <div className="modal-reserve-footer-body">
          <Button
            variant="secondary"
            className="modal-btn-footer"
            disabled={
              isLoading ||
              reserve?.status === reserve_status.SOLVED ||
              !selectedBls?.length
            }
            onClick={() => {
              handleSubmitMethod();
            }}
          >
            {reserve?.status &&
            reserve?.status !== reserve_status.SOLVED
              ? "Traiter la réserve"
              : "Réserve traitée"}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalReserve;
