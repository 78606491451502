import React from 'react'
import "./select.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

export default function Select({values, selectedValues, setSelectedValues, isOpen, setIsOpen}) {
  const handleSelectedValues = (value, from) => {
    let copySelectedValues = [...selectedValues]
    let index = selectedValues?.indexOf(value)
    if(index >= 0){
      copySelectedValues.splice(index, 1)
    }
    else{
      copySelectedValues.push(value)
    }
    setSelectedValues(copySelectedValues)
  }

  return (
    <div onClick={event => event.stopPropagation()} style={{position: "relative", maxWidth: 300}}>
      <div onClick={() => setIsOpen(!isOpen)} className='select-container'>
        {selectedValues?.map(selectedValue => {
          return(
            <div key={selectedValue} className='selected-value'>
              <div style={{marginRight: "1rem"}} onClick={() => setIsOpen(!isOpen)}>BL{selectedValue}</div>
              <div style={{cursor: "pointer", flexShrink: 1, display: "flex"}} onClick={event => {event.stopPropagation();handleSelectedValues(selectedValue)}}>
                <FontAwesomeIcon size='md' color='#F6F6F6' icon={faTimes} />
              </div>
            </div>
          )
        })}
        {selectedValues?.length < 1 && (
          <div className='pick-bl-text'>Cliquez ici pour choisir des BLs...</div>
        )}
      </div>
      {isOpen && (
        <div className='select-choices'>
          {values?.map((value, index) => {
            return(
              <div onClick={() => handleSelectedValues(value, "a")} key={value} className={`choice ${selectedValues?.includes(value) ? "checked" : ""} ${index === 0 ? "first" : ""}`}>
                <input checked={selectedValues?.includes(value)} value={value} className='checkbox' type="checkbox" />
                <div className='text'>BL{value}</div>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}
