import React, { useEffect, useState } from "react";

import { Accordion, AccordionContext } from "react-bootstrap";

import "../App/App.scss";
import TourItem from "../TourItem/TourItem";
import { EmptyListComponent } from "../EmptyListComponent/EmptyListComponent";
import MyLoader from "../Loader/MyLoader";
import SkeletonLoader from "tiny-skeleton-loader-react";

function Accccordion(props) {
  let lastDate;
  const [activeId, setActiveId] = useState(null);
  const [searchTourId, setSearchTourId] = useState(null);
  const [searchStepId, setSearchStepId] = useState(null);

  useEffect(() => {
    if (props.exactMatch) {
      setActiveId(props.exactMatch);
    }
  }, [props.exactMatch]);

  useEffect(() => {
    const url = new URL(window.location.href)
    const searchParams = new URLSearchParams(url.search)
    if(searchParams.get("tour_id")){
      setSearchTourId(searchParams.get("tour_id"))
    }
    if(searchParams.get("step_id")){
      setSearchStepId(searchParams.get("step_id"))
    }
  }, [])
  
  return (
    <div key={props.exactMatch}>
      <Accordion
        defaultActiveKey={props.exactMatch ? `${props.exactMatch}` : null}
      >
        {props.tours.map((value, index) => {
          if (lastDate !== value.starting_date) {
            lastDate = value.starting_date;
            return (
              <div key={`TourItemWrapper_${value?.tour_id}_`}>
                <h3 className={"header-tour-cat"}>
                  {new Date(value.starting_date).toLocaleDateString("fr-FR", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                  })}
                </h3>
                <div>
                  <TourItem
                    base_url={props.base_url}
                    tour={value}
                    accordionContext={AccordionContext}
                    activeId={activeId}
                    searchTourId={searchTourId}
                    setActiveId={setActiveId}
                    exactMatch={props.exactMatch}
                    searchValue={props.searchValue}
                    searchStepId={searchStepId}
                  />
                </div>
              </div>
            );
          } else {
            return (
              <TourItem
                base_url={props.base_url}
                key={`TourItemWrapper_${value?.tour_id}`}
                tour={value}
                accordionContext={AccordionContext}
                activeId={activeId}
                setActiveId={setActiveId}
                exactMatch={props.exactMatch}
                searchValue={props.searchValue}
                searchTourId={searchTourId}
                searchStepId={searchStepId}
              />
            );
          }
        })}
      </Accordion>
    </div>
  );
}

function TourItemList(props) {
  //const DeferredTimeline = deferComponentRender(Accccordion);

  if (props.tours?.length > 0) {
    return <Accccordion {...props} />;
  } else {
    if (!props.isLoading) {
      return (
        <div>
          <EmptyListComponent type={"tournée"} />
        </div>
      );
    } else {
      return (
        <div>
          <div className={"loader-section"}>
            <div className="header-tour-cat">
              <SkeletonLoader height={"26px"} width={"130px"} />
            </div>

            <MyLoader />
            <MyLoader />
          </div>
        </div>
      );
    }
  }
}

export default React.memo(TourItemList);
