import React, { Component, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { AccordionContext } from "react-bootstrap";

import { Card, Badge, Accordion, useAccordionButton } from "react-bootstrap";

import "../../../App/App.scss";
import "./BlOptimization.scss";
import earth from "../../../../assets/images/isometric/notebook.png";

function BlOptimization(props) {
  return (
    <div className="fleet-item-step-optimization-container">
      <div className={"fleet-item-bl-optimization"}>
        <div className={"fleet-first-line"}>
          <div className={"hw"}>
            <img
              src={earth}
              className={"img-illustration-fleet-step"}
              alt={"truck"}
            />
          </div>
          <div className={"fr"}>
            <span className={"fleet-item-title"}>
              {props.step.key || "BL XXXXXXXX"}{" "}
            </span>
            <span className={"fleet-item-desc"}></span>
          </div>
          <div className={"hwe"}>
            <div className="hwe-child-wrapper">
              <span className="truck-capacity">
                {props.step.products_volume} L
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(BlOptimization);
