import React from 'react'
import "./message.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeadset, faTruck, faUser, faMessage } from '@fortawesome/free-solid-svg-icons'

export default function Message({message, type}) {
  const getIcon = () => {
    switch(type){
      case "DIGITAPPRO":
        return faUser
      case "FLEET":
        return faTruck
      case "RESPONSE":
        return faHeadset
      default:
        return faMessage
    }
  }
  
  return (
    <div className='message-container'>
      <div className={`square ${type?.toLowerCase()}`}>
        <FontAwesomeIcon style={{fontSize: 11}} icon={getIcon()} color='white' />
      </div>
      <div className='text'>{message}</div>
    </div>
  )
}
