import React, { Component, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileInvoiceDollar } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import "moment/locale/fr";

import { Row, Col, Table } from "react-bootstrap";

import "../../App/App.scss";
import "./Admin.css";

import { ClientFilters } from "../../AdminComponents/ClientFilters/ClientFilters.js";

const Admin = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [invoicesPerClient, setInvoicesPerClient] = useState([]);
  const [invoicesFiltered, setInvoicesFiltered] = useState([]);

  const BASE_URL = useSelector((state) => state.BASE_URL);

  useEffect(() => {
    async function getInvoicesPerClient() {
      setIsLoading(true);
      let url = new URL(BASE_URL + "/admin/invoices");
      const token = localStorage.getItem("fleetToken");

      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", token);
      let myInit = {
        method: "GET",
        headers: myHeaders,
        cache: "no-cache",
      };

      try {
        const response = await fetch(url, myInit);

        if (response.ok) {
          const result = await response.json();

          if (result.status === "Success") {
            setInvoicesPerClient(result.invoicesPerClient);
          }
        }

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error(error);
      }
    }

    getInvoicesPerClient();
  }, [BASE_URL]);

  useEffect(() => {
    // Si un seul client est retourné, sélectionner directement celui-là.

    if (invoicesPerClient?.length === 1) {
      setInvoicesFiltered(invoicesPerClient?.[0]?.invoices);
    }
  }, [invoicesPerClient]);

  const selectInvoicesForClientId = (clientId) => {
    const invoicesFiltered =
      invoicesPerClient?.filter((client) => client?.client_id === clientId)?.[0]
        ?.invoices ?? [];

    setInvoicesFiltered(invoicesFiltered);
  };

  return (
    <>
      <div className="generic-page-wrapper">
        <div className="header-fleet">
          <div className="header-fleet-inner">
            <div>
              <div className="header-fleet-title">
                <h3>Administration</h3>
              </div>

              <h4>Consultez le détail de vos factures.</h4>
            </div>
            <div>
              <div className={"filter-status-wrapper"}>
                <div className={"filter-status-wrapper-child"}>
                  <ClientFilters
                    invoicesPerClients={invoicesPerClient}
                    selectInvoicesForClientId={selectInvoicesForClientId}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        
        {invoicesFiltered?.length > 0 && !isLoading ? (
          <>
            <table className="styled-table marginLeft">
              <thead>
                <tr>
                  <th>Période</th>
                  <th>Véhicules actifs</th>
                  <th>Abonnement</th>
                  <th>Email envoyés</th>
                  <th>SMS envoyés</th>
                  <th>Coût SMS</th>
                  <th>TOTAL FACTURE</th>
                  {/* <th>Facture</th> */}
                </tr>
              </thead>
              <tbody>
                {invoicesFiltered?.map((invoice, index) => {
                  
                  let aboPrice = 20.00;
                  let smsPrice = 0.10;
                  let vehiculeAmount = 0.00;
                  let smsAmount = 0.00;
                  
                  vehiculeAmount = invoice.vehicle_count * aboPrice;
                  smsAmount = invoice.sms_count * smsPrice;
                  
                  let totalAmount = vehiculeAmount + smsAmount;
                  
                  let periode = moment(invoice.end_date).format("MMMM YYYY");

                  return (
                    <>
                      <tr>
                        <td>{periode}</td>
                        <td>{invoice.vehicle_count}</td>
                        <td>{parseFloat(vehiculeAmount).toFixed(2)} € <span className="ht">HT</span> </td>
                        <td>{invoice.email_count}</td>
                        <td>{invoice.sms_count}</td>
                        <td>{parseFloat(smsAmount).toFixed(2)} € <span className="ht">HT</span></td>
                        <td>{parseFloat(totalAmount).toFixed(2)} € <span className="ht">HT</span></td>
                        {/* <td>
                          {" "}
                          {invoice.invoice_url ? (
                            <a
                              href={invoice.invoice_url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {" "}
                              <FontAwesomeIcon
                                className={"icon link"}
                                icon={faFileInvoiceDollar}
                              />{" "}
                            </a>
                          ) : (
                            <FontAwesomeIcon
                              className={"icon inactive"}
                              icon={faFileInvoiceDollar}
                            />
                          )}
                        </td> */}
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </>
        ) : !isLoading ? (
          <div className={"error-message"}>
            Veuillez d'abord sélectionner un client à visualiser !
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Admin;
