import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { faCheck, faAlarmClock } from '@fortawesome/pro-light-svg-icons'

import { Card, Accordion, useAccordionButton } from "react-bootstrap";

import "../App/App.scss";
import earth from "./earth.png";

import StepList from "../StepList/StepList";
import StatusComponent from "../StatusComponent/StatusComponent";
import { useSelector } from "react-redux";

import Skeleton from "react-loading-skeleton";
import { faEarthEurope, faHourglass, faHourglassClock } from "@fortawesome/pro-duotone-svg-icons";
import { faRoute } from "@fortawesome/pro-duotone-svg-icons";
import { Link, useHistory } from "react-router-dom";
import { useLayoutEffect } from "react";

const ContextAwareToggle = ({
  children,
  eventKey,
  setActiveId,
  activeId,
}) => {
  const history = useHistory()

  const decoratedOnClick = useAccordionButton(eventKey, () => {
    if (activeId === eventKey) {
      history.push("/tour")
      setActiveId && setActiveId(null)
    } else {
      history.push("/tour?tour_id="+eventKey)
      setActiveId && setActiveId(eventKey)
    }
  });

  return (
    <div
      as={Card.Header}
      onClick={decoratedOnClick}
      className={"fleet-first-line-tour"}
      id={"tour_id_accordion_"+eventKey}
    >
      {children}
    </div>
  );
};

const TourItem = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const BASE_URL = useSelector((state) => state.BASE_URL);
  const [localSteps, setLocalSteps] = useState(null);
  const titleRef = useRef();

  useEffect(() => {
    if (props.exactMatch) handleTourCollapse(props.exactMatch);
  }, [props.exactMatch]);

  useEffect(() => {
    if(props.activeId?.toString() === props.tour?.tour_id?.toString()){
      handleTourCollapse(props.activeId);
    }
  }, [props.activeId])

  useEffect(() => {
    if(props.searchTourId?.toString() === props.tour?.tour_id?.toString()){
      document.getElementById("tour_id_accordion_"+props.searchTourId)?.click()
      document.getElementById("tour_id_accordion_"+props.searchTourId)?.scrollIntoView({"behavior": "smooth", "block": "start"})
    }
  }, [props.searchTourId])

  // This function will fetch
  function handleTourCollapse(id) {
    if (localSteps == null && !isLoading) {
      setIsLoading(true);

      const url = new URL(
        `${BASE_URL}/steps/byTour/${id}${
          props.searchValue ? "?query=" + props.searchValue : ""
        }`
      );
      const token = localStorage.getItem("fleetToken");

      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", token);
      let myInit = { method: "GET", headers: myHeaders, cache: "no-cache" };

      // console.log(
      //   "GET request @ ",
      //   `${BASE_URL}/steps/byTour/${id}${
      //     props.searchValue ? "?query=" + props.searchValue : ""
      //   }`
      // );
      fetch(url, myInit)
        .then((response) => {
          if (response.ok) {
            response.json().then((result) => {
              if (result.status === "Success") {
                setLocalSteps(result.steps);
                //props.setStepsForATour(props.tour.tour_id, result.steps);
                setIsLoading(false);
              }
            });
          } else {
            setIsLoading(false);

            console.log("Mauvaise réponse du réseau");
            response.json().then(function (result) {
              console.log("result", result);
            });
          }
        })
        .catch((error) => {
          setIsLoading(false);

          console.log(
            "Il y a eu un problème avec l'opération fetch: " + error.message
          );
        });
    }
  }
  

  const getCreationDate = (creation_date) => {
    let date = new Date(creation_date)
    return date.getHours().toString().padStart(2, '0')+':'+date.getMinutes().toString().padStart(2, '0')
  }

  const statusTag = (status) => {
    switch (status) {
      case "DONE":
        return("Terminée")
      case "STARTED":
        return("En cours")
      case "CREATED":
        return("Créée")
      case "ISSUE":
        return("Problème")
      default:
        return("Error")
    }
  }
  
  return (
    <div>
      <div className={"fleet-item"} ref={titleRef}>
        <ContextAwareToggle
          eventKey={`${props.tour.tour_id}`}
          id={`${props.tour.tour_id}`}
          setActiveId={props.setActiveId}
          handleTourCollapse={handleTourCollapse}
          activeId={props.activeId}
        >
          <div className={"squaredBtn"}>
            <FontAwesomeIcon icon={faRoute} />
          </div>
          <div className={"fr"}>
            <span className={"fleet-item-title"}>
              Tournée n°{props.tour.tour_key || "tour_key"} - importée à {getCreationDate(props.tour.creation_date)}
            </span>
            <span className={"fleet-item-desc"}>
              Véhicule {props.tour.vehicule_key || <Skeleton />}{" "}
            </span>
          </div>
          <div className={"fr"}>
            <span className={"fleet-item-title"}>
              Livraisons
            </span>
            <span className={"fleet-item-desc"}>
              {props?.tour?.steps?.reduce((acc, val) => val.status == "DELIVERED" ? acc + 1 : acc, 0)} sur {props?.tour?.steps?.length}
            </span>
          </div>
          <div className={"fr"}>
            <span className={"fleet-item-title"}>
              Ponctualité
            </span>
            <span className={"fleet-item-desc"}>
              <span className={"greenState"}><FontAwesomeIcon icon={faCheck} /> {(props?.tour?.steps?.reduce((acc, val) => val.is_late == 0 ? acc + 1 : acc, 0) / props.tour?.steps?.length * 100).toFixed(2) + "%"}</span> {" - "} 
              <span className={"orangeState"}><FontAwesomeIcon icon={faHourglassClock} /> {(props?.tour?.steps?.reduce((acc, val) => val.is_late == 1 ? acc + 1 : acc, 0) / props.tour?.steps?.length * 100).toFixed(2) + "%"}</span>
            </span>
          </div>
          <div className={"fr mt-2"}>
            <div className={`statusTag ${props.tour.status} ${props.tour.is_late ? "late" : null}`}>
              {statusTag(props.tour.status)}
            </div>
          </div>
          <div className={"hwe"}>
            <div className={`lightBtn`}>
              Détails
            </div>
          </div>
        </ContextAwareToggle>

        <Accordion.Collapse
          eventKey={`${props.tour.tour_id}`}
          className="fleet-item-collapsed"
        >
          <div className="fleet-item-steps-container">
            <div className="fleet-item-steps-container-inner">
              <StepList
                tourId={props?.tour?.tour_id}
                activeId={props.activeId}
                isLoading={isLoading}
                steps={localSteps ? localSteps : []}
                exactMatch={props.exactMatch}
                searchValue={props.searchValue}
                searchStepId={props.searchStepId}
                searchTourId={props.activeId}
              />
            </div>
          </div>
        </Accordion.Collapse>
      </div>
    </div>
  );
};

export default React.memo(TourItem);
