import React, { useState, useContext, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";

import { Card, Accordion, useAccordionButton } from "react-bootstrap";

import "../App/App.scss";
import "./StepItemOptimization.scss";

import Skeleton from "react-loading-skeleton";

import box from "./box.png";
import inImg from "./StepAssets/in.png";
import outImg from "./StepAssets/out.png";
import inOutImg from "./StepAssets/in_out.png";
import BlOptimization from "../pages/Optimization/BlOptimization/BlOptimization";
const ContextAwareToggle = ({
  children,
  eventKey,
  callback,
  accordionContext,
  openedTourKey,
}) => {
  // const { activeEventKey } = useContext(accordionContext);

  const decoratedOnClick = useAccordionButton(eventKey, (e) => {
    if (openedTourKey === eventKey) {
      setTimeout(() => {
        callback && callback(null);
      }, 100);
    } else {
      callback && callback(eventKey);
    }
  });

  return (
    <div
      as={Card.Header}
      onClick={decoratedOnClick}
      className={"fleet-first-line-step"}
    >
      {children}
    </div>
  );
};

const TourItemOptimization = (props) => {
  let imgComponent = null;

  if (props.step?.bls) {
    let inValue = false;
    let outValue = false;
    props.step?.bls.map((value) => {
      if (value.delivery_type != null) {
        if (value.delivery_type === "OUT") {
          outValue = true;
        }
        if (value.delivery_type === "IN") {
          inValue = true;
        }
      }
    });

    if (inValue && !outValue) {
      imgComponent = (
        <img src={inImg} className={"img-illustration-fleet-2"} alt={"truck"} />
      );
    } else if (!inValue && outValue) {
      imgComponent = (
        <img
          src={outImg}
          className={"img-illustration-fleet-2"}
          alt={"truck"}
        />
      );
    } else if (inValue && outValue) {
      imgComponent = (
        <img
          src={inOutImg}
          className={"img-illustration-fleet-2"}
          alt={"truck"}
        />
      );
    } else {
      imgComponent = (
        <img
          src={outImg}
          className={"img-illustration-fleet-2"}
          alt={"truck"}
        />
      );
    }
  } else {
    imgComponent = (
      <img src={outImg} className={"img-illustration-fleet"} alt={"truck"} />
    );
  }

  return (
    <div>
      <div className={"fleet-item-optimization"}>
        <ContextAwareToggle
          eventKey={`${props.step?.uuid}`}
          accordionContext={props.accordionContext}
          id={`${props.step?.step_id}`}
          callback={props.setOpenedTourKey}
          openedTourKey={props.openedTourKey}
        >
          <div className={"hw"}>{imgComponent}</div>
          <div className={"fr"}>
            <span className={"fleet-item-title"}>
              {props.step.name || "Étape invalide"}{" "}
            </span>
            <span className={"fleet-item-desc"}>
              {props.step.address} {props.step.city}, {props.step.zipcode}
            </span>
          </div>
          <div className={"hwe"}>
            <div className="hwe-child-wrapper">
              {/* <span className="truck-capacity">50L</span> */}
            </div>
          </div>
        </ContextAwareToggle>

        <Accordion.Collapse
          className="fleet-item-collapsed"
          eventKey={`${props.step?.uuid}`}
        >
          <div className="fleet-item-steps-container-optimization">
            <div className="fleet-item-steps-container-inner">
              {props.step?.bls?.length > 0 ? (
                <div>
                  {props.step?.bls?.map((value, index) => {
                    return (
                      <BlOptimization
                        key={`bl_key_${props.step?.uuid}_${value?.key}`}
                        step={value}
                        stepsNumber={value.order}
                        bls={value.bls || []}
                      />
                    );
                  })}
                </div>
              ) : props.isLoading ? (
                <p>loader</p>
              ) : (
                <div className={"fleet-item-step-no-item"}>
                  <p>Aucune étape n'est associée à cette tournée.</p>
                </div>
              )}
            </div>
          </div>
        </Accordion.Collapse>
      </div>
    </div>
  );
};

export default React.memo(TourItemOptimization);
