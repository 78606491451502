import React from "react"
import ContentLoader from "react-content-loader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList, faStickyNote} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";

function ReserveBoardList(props){
    if(props.list && props.list.length > 0){
        return props.list.map((value, index) => {
            return (
                <div key={index} className={"issues-return-container"}>
                    <Link to={"/tour/"+value.tour_id}>
                        <span className={"fleet-item-desc"}><FontAwesomeIcon icon={faList}/> {value.delivery_detail_id ? value.delivery_detail_id : "00000000"}</span>
                        <span className={"fleet-item-desc2"}><FontAwesomeIcon icon={faStickyNote}/> {value.message ? value.message : "n.c"}</span>
                    </Link>
                </div>
            )
        })
    }else{
        return (
            <span className="no-bl">Aucune réserve émise.</span>
        );

    }
}

export default ReserveBoardList