import { actions } from "./Action";

const defaultState = {
  BASE_URL: process.env.REACT_APP_BASE_URL,
  auth: {
    authToken: null,
  },
  config: {
    is_admin: false,
    can_upload_with_excel: false,
    can_access_dashboard_v2: false,
    fleet_optimization: false,
    logo_url: false,
  },
  tours: [],
  canceledBls: [],
  returnBls: [],
  vehicles: [],
  nav_width: "expanded",
};

function reducers(state = defaultState, action) {
  switch (action.type) {
    case actions.AUTHENTICATE:
      return Object.assign({}, state, {
        auth: { isAuthenticated: true, authToken: action.token },
      });
    case actions.LOGOUT:
      return Object.assign({}, state, {
        auth: {
          isAuthenticated: false,
          authToken: null,
        },
        config: {
          can_upload_with_excel: false,
          can_access_dashboard_v2: false,
          fleet_optimization: false,
        },
      });
    case actions.SET_TOURS:
      return Object.assign({}, state, { tours: action.tours });
    case actions.CONFIG:
      return Object.assign({}, state, { config: action.config });
    case actions.SET_VEHICLE_PIN:
      return Object.assign({}, state, {
        vehicles: state.vehicles.map((value, index) => {
          if (value.vehicule_id === action.vehicle_id) {
            return Object.assign({}, value, {
              pin: action.pin,
            });
          }
          return value;
        }),
      });
    case actions.SET_STEPS_FOR_A_TOUR:
      return Object.assign({}, state, {
        tours: state.tours.map((value, index) => {
          if (value.tour_id === action.tour_id) {
            return Object.assign({}, value, {
              steps: action.steps,
            });
          }
          return value;
        }),
      });
    case actions.SET_BLS_FOR_A_TOUR:
      return Object.assign({}, state, {
        tours: state.tours.map((value, index) => {
          if (value.tour_id === action.tour_id) {
            return Object.assign({}, value, {
              steps: value.steps.map((value2, index) => {
                if (value2.step_id === action.step_id) {
                  return Object.assign({}, value2, {
                    bls: action.bls,
                  });
                }
                return value2;
              }),
            });
          }
          return value;
        }),
      });
    case actions.VEHICLES:
      return Object.assign({}, state, { vehicles: action.vehicles });
    case actions.SET_NAV_WIDTH:
      return Object.assign({}, state, { nav_width: action.nav_width });
    default:
      return state;
  }
}

export default reducers;
