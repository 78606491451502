import './CardContract.css'
import { truncateString } from '../../../../utils/truncate-string';
import { reserve_status } from '../../../../constants/reserve_status'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard } from "@fortawesome/free-regular-svg-icons";

const CardContract = ({ noBorderBottom, onHandleReserveClick, reserve }) => {
  return (
    <div
      
      className="list-container-item"
      style={noBorderBottom ? { borderBottom: 'none' } : {}}
    >
      <div className="icon-container">
        <div
          className={`icon-wrapper`}
        >
          <FontAwesomeIcon icon={faClipboard} height={19} className='select-none' color="#ABB5BF"
            width={19} />
        </div>
      </div>
      <div
        className="text-container"
      >
        <span className="title">
          {reserve?.delivery_detail_key || "n.c"}
        </span>
        <span className="description" >
          {new Date(reserve?.created_at).toLocaleDateString(
              "fr-FR",
              {
                year: "numeric",
                month: "short",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
              }
            ) || "Pas d'information de date"}
        </span>
      </div>
      <div className="text-container" >
        <span className="title">
          Message
        </span>
        <span className="description" >
          {reserve?.message?.length > 0 ? `${truncateString(reserve.message, 20)}`: "Message non défini"}
        </span>
      </div>
      <div className="text-container">
        <span className="title">
          Destinataire 
        </span>
        <span className="description">
         {reserve?.receiver_name ? reserve.receiver_name : "n.c"}
        </span>
      </div>
      <div className="text-container">
        <span className="title">
          Provenance
        </span>
        <span className="description">
          {reserve?.type || "n.c"}
        </span>
      </div>
      <div className="text-container ml-2" style={{  textTransform: "none" }}>
        <span className="badge" style={{color: reserve_status.properties[reserve?.status]?.color}}>
          {reserve_status.properties[reserve?.status]?.display_name}
        </span>
      </div>
      <div className="text-container ml-2 justify-content-end align-items-center d-flex flex-row gap-1" style={{ flex: '0 0 170px', textTransform: "none" }}>
        <button className={`button-primary${reserve?.status === reserve_status.SOLVED ? "-white" : ""}`} onClick={() => onHandleReserveClick(reserve)}>{reserve?.status === reserve_status.SOLVED ? "Consulter" : "Traiter"}</button>
      </div>
      
    </div>
  );
};

export default CardContract;
