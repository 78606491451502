import React from "react";
import styles from "./tchat.scss";
import moment from "moment";

const getFormattedDate = (date) => {
  return moment(date).format("ddd DD MMM à HH:mm");
};

export default function Message({ message }) {
  return (
    <div className={"messageContainer"}>
      <div>
        <span>{message?.reserve ? "Reserve" : "Réponse"} :</span>
      </div>
      <div>
        <div
          className={`${"message"} ${
            message?.reserve ? "messageSent" : "messageReceived"
          }`}
        >
          <div className={styles.text}>{message?.text}</div>
        </div>
        <div className={"date"}>
          {message?.reserve
            ? `${message?.username || "Un utilisateur"} a émis une réserve le`
            : "Le service de livraison à répondu"}{" "}
          {getFormattedDate(message?.date)}
        </div>
      </div>
    </div>
  );
}
