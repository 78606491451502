import React, { forwardRef } from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";

const CustomDatePickerDashboard = forwardRef(({ value, onClick }, ref) => (
  <Button
    variant=""
    className="btn-fleet-header"
    style={{ marginRight: "15px" }}
    onClick={onClick}
  >
    <FontAwesomeIcon icon={faCalendarAlt} className="btn-icon" /> {value}
  </Button>
));

export default React.memo(CustomDatePickerDashboard);
