import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import jwt_decode from "jwt-decode";

import { Container, Row, Col, Form, Button, InputGroup } from "react-bootstrap";

import "../App/App.scss";
import "./Login.scss";
import { Redirect, withRouter, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { authenticate, setConfiguration } from "../../reducer/Action";
import fleetBrand from "../App/FleetWhite.png";
import fleetBrandGreen from '../App/FleetMain.png';
import agrivitech from '../../assets/images/logos/logoAgrivitech.png';
import biBG from '../../assets/images/background/headerbg.svg';
import optimisation from "../../assets/images/illustration/Intersection 2_imac2013_right.png";
import flotte from "../../assets/images/illustration/Intersection 3_imac2013_left.png";
import app from "../../assets/images/illustration/app.png";
import farmer from "../../assets/images/illustration/farmer.svg";
import mapMarker from "../../assets/images/illustration/mapMarker.png";
import { 
  faEnvelope,
  faExclamationCircle,
  faTruckFast,
  faTruckLoading,
  faRoute,
  faSmile,
  faFile,
  faParachuteBox,
  faStar,
  faShield,
  faLocationDot,
  faMessageBot,
 } from "@fortawesome/pro-duotone-svg-icons";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      testObject: null,
      errorMessage: "",
      errorStatus: false,
    };
  }

  login = (e) => {
    e.preventDefault();

    const url = new URL(this.props.BASE_URL + "/auth/byCredential");
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let myInit = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        email: this.state.email,
        password: this.state.password,
      }),
      cache: "no-cache",
    };

    fetch(url, myInit)
      .then((response) => {
        // console.log("POST request @ ",  this.props.BASE_URL+'/auth/byCredential');
        if (response.ok) {
          response.json().then((result) => {
            if (result.status === "Bad credentials") {
              this.setState({
                errorMessage: "Mot de passe ou e-mail incorrect.",
                errorStatus: true,
              });
            } else {
              if (result.token) {
                localStorage.setItem("fleetToken", result.token);
                localStorage.setItem("fleetClientId", result.clientId);
                //decode the token and store it in state
                let decoded = result.token ? jwt_decode(result.token) : null;
                if (decoded) {
                  this.props.setConfiguration({
                    is_admin: decoded?.user?.is_admin === 1 ? true : false,
                    can_upload_with_excel:
                      decoded?.user?.can_upload_with_excel === 1 ? true : false,
                    can_access_dashboard_v2:
                      decoded?.user?.can_access_dashboard_v2 === 1
                        ? true
                        : false,
                    fleet_optimization:
                      decoded?.user?.fleet_optimization === 1 ? true : false,
                    logo_url: decoded?.user?.logo_url,
                  });
                }
                this.setState({
                  email: "",
                  password: "",
                });
              }
            }
          });
        } else if (response.status === 401) {
          response.json().then((result) => {
            // console.log("result", result);

            if (result.status === "Bad credentials") {
              this.setState({
                errorMessage: "Mot de passe ou e-mail incorrect.",
                errorStatus: true,
              });
            }
          });
        } else if (response.status === 400) {
          this.setState({
            errorMessage: "Une erreur s'est produite",
            errorStatus: true,
          });
        } else {
          this.setState({
            errorMessage:
              "Une erreur réseau est survenue, veuillez contacter le support client.",
            errorStatus: true,
          });
        }
      })
      .catch(function (error) {
        console.log(
          "Il y a eu un problème avec l'opération fetch: " + error.message
        );
      });

    //this.props.authenticate("teub");

    // console.log("connected", this.props.isAuthenticated );
  };

  handleEmailChange = (e) => {
    const email = e.target.value;
    this.setState({
      email: email,
    });
  };

  handlePasswordChange = (e) => {
    const password = e.target.value;
    this.setState({
      password: password,
    });
  };

  render() {
    if (checkAuth()) {
      let decoded = jwt_decode(localStorage.getItem("fleetToken"));
      return (
        <Redirect
          to={decoded?.user?.can_access_dashboard_v2 === 1 ? "/" : "/vehicle"}
        />
      );
    }

    

    return (
      <>

      <div className="bigHeader" style={{backgroundImage:`url(${biBG})`}}>
        <div className="container headerContainer">
          <Col sm={12} md={7} >
            <img src={agrivitech} alt="logo" />
            <h1 className="mainTitle"> FLEET SIMPLIFIE LA GESTION DES TRANSPORTS D'APPRO, LA COLLECTE OU LES LIVRAISONS DU SECTEUR AGRICOLE </h1>
            <p className="mb-5 bold">
              Solution innovante permettant d'optimiser rapidement vos tournées de livraison, de notifier en temps réel vos destinataires et de dématérialiser les documents de livraison.
            </p>
            <img src={mapMarker} className='mapMarker'  alt="logo" />
          </Col>
          <Col></Col>
          <Col sm={12} md={4} >
            <Form>
              <div className={"error-message-div"}>
                <div
                  style={{
                    display: this.state.errorStatus ? "block" : "none",
                  }}
                  className={"error-msg-login"}
                >
                  <FontAwesomeIcon icon={faExclamationCircle} />{" "}
                  {this.state.errorMessage}
                </div>
              </div>
              <Form.Label className="label-2">Adresse email</Form.Label>
              <Form.Control
                className="custom-input-login"
                type="email"
                placeholder="Votre adresse email"
                autoComplete="username"
                onChange={(e) => this.handleEmailChange(e)}
                defaultValue={this.state.email}
                aria-describedby="basic-addon1"
              />
              <Form.Label className="label-2">Mot de passe</Form.Label>
              <Form.Control
                className="custom-input-login"
                type="password"
                placeholder="Votre mot de passe"
                autoComplete="current-password"
                onChange={(e) => this.handlePasswordChange(e)}
                defaultValue={this.state.password}
                aria-describedby="basic-addon2"
              />

              <div
                className="d-flex justify-content-between align-items-center"
                style={{ width: "100%" }}
              >
                <Link to="/forgot">
                  <p className="password-forget mt-4 mb-0">
                    Mot de passe oublié ?
                  </p>
                </Link>
                <Button
                  variant=""
                  type="submit"
                  onClick={(e) => this.login(e)}
                  className={"btn-login mt-4"}
                  block="true"
                  disabled={
                    this.state.email === "" || this.state.password === ""
                  }
                >
                  Connexion
                </Button>
              </div>
            </Form>
          </Col>
        </div>
      </div>
      <div className="container">
      <div className="mt-5 mb-5">
          <Row className="imgRow">
            <Col className="coloredBanner">
              <div className="bigTitle">
                Optimisez et suivez automatiquement vos tournées en temps réel
              </div>
              <p>
                Application mobile pour l’exécution de la tournée du livreur
                Réduction de l’impact carbone des livraisons
                Notification en temps réel par sms, email, notification push, etc...
                Preuve de livraison par signature ou photo géolocalisée
                Dématérialisation des documents de livraison
                UX co-développée avec des chauffeurs pour une expérience optimale
              </p>
            </Col>
            <Col sm={12} md={6} >
            <div className="background-img d-flex align-items-center justify-content-center">
              <img src={flotte} className="carou" alt="flotte"  />
            </div>
            </Col>
          </Row>
        </div>

        <div className="mt-5 mb-5">
          <Row  className="imgRow">
  
            <Col sm={12} md={5} className="text-center">
              <img src={optimisation} className="carou"  alt="logo" />
              {/* <img src={tourdecontrol} className="" alt="logo" /> */}
            </Col>
            <Col sm={12} md={7}>
            <Row>
                <Col  sm={6} className="d-flex">
                  <div>
                    <FontAwesomeIcon className="iconLarge" icon={faFile} />
                  </div>
                  <div>
                    <div className="title">
                    Documents de transport dématérialisés
                    </div>
                    <div className="content">
                    Vos bons de livraison dématérialisés sont échangés en temps réel entre vos équipes et le client pour réduire les délais de traitement, facturation...
                    </div>
                  </div>
              </Col>
              <Col  sm={6} className="d-flex">
                  <div>
                    <FontAwesomeIcon className="iconLarge" icon={faParachuteBox} />
                  </div>
                  <div>
                    <div className="title">
                    30% de retours en moins
                    </div>
                    <div className="content">
                    Avec la livraison sans présence du destinataire et l'affichage dans l'application du lieu de dépose en ferme réduisez retours : <strong>30% de retours en moins !</strong>
                    </div>
                  </div>
              </Col>
              <Col sm={6} className="d-flex">
                  <div>
                    <FontAwesomeIcon className="iconLarge" icon={faStar} />
                  </div>
                  <div>
                    <div className="title">
                    Gestion des anomalies
                    </div>
                    <div className="content">
                    Les anomalies de livraison (annulation, refus, réserve, etc) sont remontées en temps réel pour un maximum de réactivité
                    </div>
                  </div>
              </Col>
              <Col  sm={6}className="d-flex">
                  <div>
                    <FontAwesomeIcon className="iconLarge" icon={faShield} />
                  </div>
                  <div>
                    <div className="title">
                    Sécurisez les lieux de livraison
                    </div>
                    <div className="content">
                    Vos livreurs pourront prendre en photo les lieux de livraison dangereux ou non conformes pour vous permettre une meilleure gestion
                    </div>
                  </div>
              </Col>
                </Row>
            </Col>
          </Row>
        </div>

        <div className="mt-5 mb-5">
          <Row className="imgRow">
            <Col sm={12} md={7}>
                  {/* <div className="lightBtn">
                  Générez des tournées optimisées en quelques secondes 
                  </div>
                  <div className="lightBtn">
                  Mesurer l'impacte financier et environnemental de vos livraisons dans le temps
                  </div>
                  <div className="lightBtn">
                  Suivez votre livraisons en temps réel et gagnez en réactivité
                  </div>
                  <div className="lightBtn">
                  Sécurisez vos opérations en identifiants les lieux de livraison
                  </div> */}
                  <Row>
                <Col  sm={6} className="d-flex">
                  <div>
                    <FontAwesomeIcon className="iconLarge" icon={faTruckFast} />
                  </div>
                  <div>
                    <div className="title">
                      Livrez sans présence du destinataire
                    </div>
                    <div className="content">
                      Preuve de livraison par photo datée et géolocalisée avec notification instantanée au destinataire
                    </div>
                  </div>
              </Col>
              <Col  sm={6} className="d-flex">
                  <div>
                    <FontAwesomeIcon className="iconLarge" icon={faTruckLoading} />
                  </div>
                  <div>
                    <div className="title">
                      Chargement intelligent de vos camions
                    </div>
                    <div className="content">
                    Fleet génère automatiquement l'ordre de chargement optimal de vos camions standards ou citernes
                    </div>
                  </div>
              </Col>
              <Col sm={6} className="d-flex">
                  <div>
                    <FontAwesomeIcon className="iconLarge" icon={faRoute} />
                  </div>
                  <div>
                    <div className="title">
                    Optimisation de vos tournées
                    </div>
                    <div className="content">
                    Obtenez en quelques secondes les simulations de trajets optimaux qui s'adapte à vos contraintes métiers
                    </div>
                  </div>
              </Col>
              <Col  sm={6}className="d-flex">
                  <div>
                    <FontAwesomeIcon className="iconLarge" icon={faSmile} />
                  </div>
                  <div>
                    <div className="title">
                    Contact destinataire facilité
                    </div>
                    <div className="content">
                    Appel téléphonique du destinataire depuis l'application, notification automatique à l'approche de la livraison, remontée d'anomalies
                    </div>
                  </div>
              </Col>
                </Row>
            </Col>
            <Col sm={12} md={5} className="text-center">
              <img src={app} className="carou"  alt="logo" />
              {/* <img src={tourdecontrol} className="" alt="logo" /> */}
            </Col>
          </Row>
        </div>
          
        <h2 className="text-center bigTitle">Réduisez l'empreinte carbone graçe aux fonctionalités de l'application Fleet®</h2>
        <Row className="imgRow">
          <Col className="coloredBanner">
            <p className="title">
              Moins de kilomètres, moins de temps de livraison, moins de papier !
            </p>
            <ul>
              <li>Moins de kilomètres : l'application réduit le nombre de retours plateforme en permettant de livrer sans la présence de l'agriculteur.</li>
              <li>Moins de temps sur place : en prévenant les destinataires avant l'arrivée vous réduisez le temps de livraison</li>
              <li>Moins de papier : fin des bons de livraison papier</li>
            </ul>
          </Col>
          <Col sm={12} md={5}>
            <img src={farmer} className="carou" alt="logo" />
          </Col>
        </Row>
        
        
        
    
      </div>

      <div className="footer">
      <div className="brandRow"><img src={fleetBrand} alt="logo" className="img" height={40} /> {"  "} par {"  "} <img src={agrivitech} alt="logo" className="img"  height={40} /> </div>
        <div><FontAwesomeIcon icon={faLocationDot} /> 17 rond-point de l'Europe <br />
        51430 Bezannes</div>
        
        <div><FontAwesomeIcon icon={faEnvelope} /> contact@agrivitech.com</div>
        <div><FontAwesomeIcon icon={faMessageBot} /> <a href="https://discord.gg/t5GE2wTDSk">https://discord.gg/t5GE2wTDSk</a> </div>
      </div>

      
      </>
    );
  }
}

const checkAuth = () => {
  return localStorage.getItem("fleetToken");
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    BASE_URL: state.BASE_URL,
    config: state.config,
  };
};
const mapDispatchToProps = { authenticate, setConfiguration };

export default connect(mapStateToProps, mapDispatchToProps)(Login);
